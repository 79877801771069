import React, {useEffect, useState} from "react";
import {Link, Modal, ModalSize, Spinner} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import styles from "./EasyPlusInfoModal.module.scss";
import {Button, ButtonSize, ButtonType} from "@renta-apps/renta-react-components";

interface EasyPlusInfoModalProps {
    isOpen: boolean;
    onClose(): void;
    onContactMeClick(): void;
}

const EasyPlusInfoModal: React.FC<EasyPlusInfoModalProps> = ({isOpen, onClose, onContactMeClick}) => {
    const [isBeingSent, setIsBeingSent] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const _modalRef: React.RefObject<Modal> = React.createRef();

    useEffect(() => {
        if (isOpen) {
            _modalRef.current?.openAsync();
        } else {
            _modalRef.current?.closeAsync();
        }
    }, [_modalRef, isOpen]);

    const handleContactMeClick = async () => {
        setIsBeingSent(true);
        try {
            await onContactMeClick();
            setIsSent(true);
        } catch (error) {
            console.error('Error sending contact request:', error);
        } finally {
            setIsBeingSent(false);
        }
    };

    return isOpen ? (
        <Modal id="easy-plus-info-modal"
               ref={_modalRef}
               preventEsc preventClosingOnInsideClick
               size={ModalSize.Large}
               title={Localizer.easyPlusInfoModalTitle}
               className={styles.modal}
               onClose={async () => onClose()}
        >
                <div className={styles.easyPlusInfo}>
                    <div className={styles.easyPlusInfoContent}>
                        <div>
                            <div data-cy="line1">{Localizer.easyPlusInfoPanelLine1}</div>
                            <div data-cy="line2">{Localizer.easyPlusInfoPanelLine2}</div>
                        </div>
                        <div>
                            <div data-cy="line3">
                                {Localizer.easyPlusInfoPanelLine3}
                                &nbsp;
                                <Link className={styles.easyPlusLink}
                                      route={PageDefinitions.easyPlus.route()}
                                >
                                    {Localizer.easyPlusInfoPanelReadMore}
                                </Link>
                            </div>
                        </div>
                    </div>

                {!isBeingSent && !isSent && (
                    <div className={styles.easyPlusContactMeButton} data-cy="contactMe">
                        <Button onClick={async() => await handleContactMeClick()} size={ButtonSize.Default} type={ButtonType.Primary}>
                            {Localizer.easyPlusInfoModalContactMe}
                        </Button>
                    </div>
                )}

                {isBeingSent && !isSent && (
                    <div className={styles.easyPlusContactMeButton}>
                        <div className={styles.spinnerContainer}>
                            <Spinner noShading />
                        </div>
                        <div className={styles.textContainer}>
                            <span data-cy="pleaseWait">{Localizer.fleetMonitoringPageSubscribeToAlertsModalPleaseWait}</span>
                        </div>
                    </div>
                )}

                {!isBeingSent && isSent && (
                    <div className={styles.easyPlusContactMeButton}>
                        <div className={styles.textContainer}>
                            <span data-cy="thankYou">{Localizer.easyPlusInfoModalThankYou}</span>
                        </div>
                    </div>
                )}

                </div>
        </Modal>
    ) : null;
}

export default EasyPlusInfoModal;
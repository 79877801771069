import React, {useEffect, useState} from "react";
import {Link, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import styles from "./EasyPlusConfirmationModal.module.scss";
import {Button, ButtonSize, ButtonType, Checkbox} from "@renta-apps/renta-react-components";

interface EasyPlusConfirmationModalProps {
    isOpen: boolean;
    userFullName: string;
    onClose(confirmed: boolean): Promise<void>;
    onTermsRead(): Promise<void>;
    termsRead: boolean;
}

const EasyPlusConfirmationModal: React.FC<EasyPlusConfirmationModalProps> = ({isOpen, userFullName, onClose, onTermsRead, termsRead}) => {
    const _modalRef: React.RefObject<Modal> = React.createRef();
    const _termsRef: React.RefObject<HTMLDivElement> = React.createRef();

    const [isAccepted, setIsAccepted] = useState(false);
    const [scrolledDown, setScrolledDown] = useState(termsRead);

    useEffect(() => {
        if (isOpen && _termsRef.current) {
            setTimeout(() => {
                if (_termsRef.current && _termsRef.current.clientHeight === _termsRef.current.scrollHeight) {
                    setScrolledDown(true);
                }
            }, 50);
        }
    }, [_termsRef, isOpen]);

    useEffect(() => {
        if (isOpen) {
            _modalRef.current?.openAsync();
        } else {
            _modalRef.current?.closeAsync();
        }
    }, [_modalRef, isOpen]);
    
    const close = async (confirmed: boolean) => {
        setIsAccepted(false);
        setScrolledDown(termsRead);
        await onClose(confirmed);
    };

    const handleTermsScroll = (event: React.UIEvent<HTMLDivElement>) => {
        if (scrolledDown || termsRead) {
            return;
        }
        
        const target = event.target as HTMLDivElement;
        if (target.scrollHeight - target.scrollTop - target.clientHeight <= 1) {
            setScrolledDown(true);
            onTermsRead().catch();
        }
    };

    return isOpen ? (
        <Modal id="easy-plus-confirmation-modal"
               ref={_modalRef}
               preventEsc preventClosingOnInsideClick
               size={ModalSize.Large}
               title={Localizer.componentConfirmationDialogAreYouSure}
               className={styles.modal}
               onClose={async () => await close(false)}
        >
            <div className={styles.easyPlusConfirmation}>
                <div className={styles.easyPlusConfirmationContent}>
                    <div>
                        {Localizer.get(Localizer.easyPlusConfirmationDialogAreYouSure, userFullName)}
                        &nbsp;
                        <Link className={styles.easyPlusLink}
                              route={PageDefinitions.easyPlus.route()}
                        >
                            {Localizer.easyPlusInfoPanelReadMore}
                        </Link>
                    </div>
                    <div className={styles.easyPlusTerms}
                         onScroll={(event) => handleTermsScroll(event)}
                         ref={_termsRef}
                         data-cy="easy-plus-terms"
                    >
                        <div className={styles.header}>{Localizer.easyPlusConfirmationDialogTermsAndCondtions}</div>
                        <div>
                            <ol className={styles.termsList}>
                                <li>Phasellus varius ligula ac ligula dignissim tincidunt. Sed euismod lorem rhoncus pretium feugiat. Cras lacinia lacus vel congue ullamcorper. Morbi pretium dolor a neque cursus, semper dapibus diam semper. Pellentesque convallis pretium auctor. In et leo tellus.</li>
                                <li>Curabitur ac felis eu odio auctor rhoncus sed eu nunc. Morbi ac metus et sem ullamcorper vulputate ut sed metus. Quisque scelerisque semper euismod. In ultricies eros vel interdum tincidunt. Suspendisse facilisis suscipit velit ut tempor. In tempus in nulla non rhoncus. Vestibulum egestas dui at posuere consectetur. Integer cursus ligula ut massa ultricies volutpat.</li>
                                <li>Morbi erat nibh, sollicitudin vel eros et, volutpat feugiat nisl. Pellentesque vulputate diam sit amet velit elementum, vel consectetur enim commodo.</li>
                                <li>Morbi vitae quam sit amet urna pulvinar placerat a vel ante. Nulla at purus pulvinar, condimentum tortor eu, convallis lacus. Nulla eget egestas ipsum. Aliquam id dolor ligula. Sed semper diam eu ligula sagittis feugiat. Vivamus sollicitudin, mi eget condimentum tristique, nibh nibh sodales nunc, at feugiat mi lorem vel nunc. Duis dapibus nunc sit amet finibus malesuada. Vestibulum</li>
                            </ol>
                        </div>
                    </div>
                    <div>
                        {(!termsRead && !scrolledDown) && (
                            <div className={styles.scrollDown}>
                                {Localizer.easyPlusConfirmationDialogScrollToEnd}
                            </div>
                        )}
                        <Checkbox id="accept-checkbox"
                                  className={styles.acceptCheckbox}
                                  checked={isAccepted}
                                  onChange={(value: boolean) => setIsAccepted(value)}
                                  label={Localizer.easyPlusConfirmationDialogAcceptTermsAndCondtions}
                                  disabled={!scrolledDown}
                        />
                    </div>
                </div>
                <div className={styles.easyPlusConfirmationButtons}>
                    <Button onClick={() => close(false)}
                            size={ButtonSize.Default}
                            type={ButtonType.Secondary}
                            className={styles.button}
                    >
                        {Localizer.formCancel}
                    </Button>
                    <Button onClick={() => close(true)}
                            size={ButtonSize.Default}
                            type={ButtonType.Primary}
                            className={styles.button}
                            disabled={!isAccepted}
                            id="activate-easy-plus-button"
                    >
                        {Localizer.easyPlusConfirmationDialogActivate}
                    </Button>
                </div>
            </div>
        </Modal>
    ) : null;
}

export default EasyPlusConfirmationModal;
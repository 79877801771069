export default class ConstructionSiteReturn {
    public name: string | null = null;

    public count: number = 0;

    public rentaId: string | null = null;

    public rentalObjectId: string | null = null;

    public inventoryItemDisplayNumber: string | null = null;

    public contractNumber: string | null = null;

    public rentalObjectNumber: string | null = null;

    public depotExternalId: number = 0;

    public orderLineId: number = 0;
}
// @ts-ignore
import Localizer from "@/localization/Localizer";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import { executeWithIntercept, onRedirect, pageData, RouteData } from "@/helpers/CypressHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

context("(Desktop) Construction site page: Emissions tab", () => {

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        cy.intercept('POST', pageData().company.routes.getEquipmentEmissionData.path, { fixture: 'companyDetailsEmissions.json' });

        onRedirect(() => {
            executeWithIntercept(() =>
                cy.visit(`${Localizer.pageRoutesContractDetails}?id=31eaa6c0-42fa-40c2-a891-bcd16c367409`),
                [pageData().company.routes.getContractData as RouteData]
            );
        });

    });

    it("Open emissions tab and verify chart renders", () => {

        executeWithIntercept(() =>
            pageData().company.emissions.tab().click(),
            [pageData().company.routes.getEquipmentEmissionData as RouteData]
        );

        pageData().company.emissions.infoBox()
            .should('be.visible')
            .children('div')
            .should('have.length', 3)

        pageData().company.emissions.infoBox()
            .children('div')
            .eq(0)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 17)

        pageData().company.emissions.infoBox()
            .children('div')
            .eq(1)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 34)


        pageData().company.emissions.infoBox()
            .children('div')
            .eq(2)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 1100.5)
    });

});

context("Non-easy plus user context", () => {

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Uuno.turhapuro@weare.fi", "tmi jukka wilska");
        });

        cy.intercept('POST', pageData().company.routes.getEquipmentEmissionData.path, { fixture: 'companyDetailsEmissions.json' });

        onRedirect(() => {
            executeWithIntercept(() =>
                cy.visit(`${Localizer.pageRoutesContractDetails}?id=31eaa6c0-42fa-40c2-a891-bcd16c367409`),
                [pageData().company.routes.getContractData as RouteData]
            );
        });
    });

    it("Open emissions tab and verify chart renders", () => {

        executeWithIntercept(() =>
            pageData().company.emissions.tab().click(),
            [pageData().company.routes.getEquipmentEmissionData as RouteData]
        );

        pageData().company.emissions.infoBox()
            .should('be.visible')
            .children('div')
            .should('have.length', 3)

        pageData().company.emissions.infoBox()
            .children('div')
            .eq(0)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 17)

        pageData().company.emissions.infoBox()
            .children('div')
            .eq(1)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 34)


        pageData().company.emissions.infoBox()
            .children('div')
            .eq(2)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 1100.5)
    });

    it("Open emissions tab and verify Easy Plus notice", () => {

        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagFeatureCo2RequiresPlus);

        cy.reload();
        pageData().common.waitTopNavRender();

        pageData().company.emissions.tab().click();

        cy.get('div[data-cy="easyPlusTitle"]').should('contain', Localizer.easyPlusFeatureTitle);
        
        cy.get('div[data-cy="easyPlusText"]').should('contain', Localizer.easyPlusNewSubscriptionInfo);
        
        cy.get('button').should('contain', Localizer.easyPlusNewSubscriptionInfoButton);

        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagFeatureCo2RequiresPlus);

    });

});

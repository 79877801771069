import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import {ContractDetailsData} from "@/models/server/ContractDetailsData";
import queryString from "query-string";
import {CompanyName} from "@/models/server/CompanyName";
import GetUsersPagedListRequest from "@/models/server/Requests/GetUsersPagedListRequest";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import UserModel from "@/models/server/UserModel";

export const getContractData = async (contractId: string): Promise<ContractDetailsData> => {
    return ApiProvider.postAsync<ContractDetailsData>("/api/Companies/GetContractData", contractId, null);
};

export const getUsersPagedList = async (
    contractId: string,
    pageNumber: number = 1,
    pageSize: number = 20000,
    notProcessedUsers: boolean = false,
    sortColumnName: string = "name",
    sortDirection: number = 0,
    keyWord: string | null = null,
    selectedDepotId: string | null = null,
    selectedRoleName: string | null = null,
): Promise<IPagedList<UserModel>> => {
    const request: GetUsersPagedListRequest = {
        contractId,
        pageNumber,
        pageSize,
        notProcessedUsers,
        sortColumnName,
        sortDirection,
        keyWord,
        selectedDepotId,
        selectedRoleName,
    };

    return ApiProvider.postAsync<IPagedList<UserModel>>("/api/Companies/GetUsersPagedList", request, null);
};

export const searchCompanyNames = async (searchTerm: string, searchLimit: number = 50): Promise<CompanyName[]> => {
    const query = queryString.stringify({searchTerm, searchLimit});
    return ApiProvider.getAsync<CompanyName[]>(`/api/Companies/SearchCompanyNames?${query}`, null);
};
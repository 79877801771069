import React, {useEffect, useMemo, useState} from "react";
import styles from "./FleetMonitoringContainer.module.scss";

import {Spinner} from "@renta-apps/athenaeum-react-components";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {Pagination} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import AlarmModel from "@/models/server/AlarmModel";
import {DeviceModel} from "@/models/server/DeviceModel";
import {DeviceDetails} from "@/models/server/DeviceDetails";
import UserInteractionDataStorage, {DataStorageType} from "@/providers/UserInteractionDataStorage";
import {getDeviceDebugDetailsAsync, getDeviceDetailsAsync, getDevicesDetailsAsync, getDevicesPagedListAsync} from "@/services/FleetService";
import DeviceBottomSheet from "./DeviceBottomSheet/DeviceBottomSheet";
import DeviceGrid from "./DeviceGrid/DeviceGrid";
import SubscribeToAlertsModal from "./Modals/SubscribeToAlertsModal";
import ViewControl, {FleetMonitoringFilters} from "./ViewControl/ViewControl";
import {getAlertsConfig} from "@/services/AlarmService";
import AlertsConfigResponse from "@/models/server/Responses/AlertsConfigResponse";
import DeviceDetailsRequest from "@/models/server/Requests/DeviceDetailsRequest";

export interface INewAlarm {
    deviceId: string,
    configuredAlert: AlarmModel,
}

export interface IDeletedAlarm {
    deviceId: string,
    alarmId: string,
}

interface IFleetMonitoringContainerProps {
    urlParams?: FleetMonitoringFiltersAndPagination;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean;
    onConstructionSiteLinkClick: (constructionSiteId: string) => void;
    onCompanyLinkClick: (contractId: string) => void;
    onFilterParamsChange: (filters: FleetMonitoringFiltersAndPagination) => void;
}

export class FleetMonitoringFiltersAndPagination {
    constructor(
        public constructionSite?: string,
        public constructionSiteId?: string,
        public deviceName?: string,
        public productGroup?: string,
        public productGroupId?: string,
        public company?: string,
        public companyId?: string,
        public pageNumber?: number,
        public pageSize?: number,
        public sortBy?: string,
        public sortOrder?: string,
    ) { }
}

const FleetMonitoringContainer: React.FC<IFleetMonitoringContainerProps> = ({
    urlParams,
    userRoleConstructionSiteId,
    userRoleContractId,
    userRoleIsAdmin,
    onConstructionSiteLinkClick,
    onCompanyLinkClick,
    onFilterParamsChange,
}) => {
    // Store user filter selections.
    const [paginationPageNumber, setPaginationPageNumber] = useState<number>(1);
    const [paginationPageSize, setPaginationPageSize] = useState<number>(25);
    const [sortAndFilters, setSortAndFilters] = useState<FleetMonitoringFilters | undefined>(undefined);

    // Other state variables.
    const [filteredDevices, setFilteredDevices] = useState<DeviceModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubscribeToAlertsModalOpen, setIsSubscribeToAlertsModalOpen] = useState<boolean>(false);
    const [selectedDevicesIds, setSelectedDevicesIds] = useState<string[]>([]);
    const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false);
    const [totalItemCount, setTotalItemCount] = useState<number>(1);
    const [alertsConfig, setAlertsConfig] = useState<AlertsConfigResponse>(new AlertsConfigResponse());

    const USER_DATA_STORAGE_KEY = useMemo(() => {
        return `FleetMonitoringFilters-${userRoleContractId}-${userRoleConstructionSiteId}`;
    }, [userRoleContractId, userRoleConstructionSiteId]);

    useEffect(() => {
        let initialParams = urlParams;
        if (!initialParams) {
            initialParams = UserInteractionDataStorage.get(USER_DATA_STORAGE_KEY, new FleetMonitoringFiltersAndPagination(), DataStorageType.Page);
        }

        const initialFilters = {
            constructionSite: initialParams!.constructionSite,
            constructionSiteId: initialParams!.constructionSiteId,
            deviceName: initialParams!.deviceName,
            productGroup: initialParams!.productGroup,
            productGroupId: initialParams!.productGroupId,
            company: initialParams!.company,
            companyId: initialParams!.companyId,
            sortBy: initialParams!.sortBy ?? 'IdleDays',
            sortOrder: initialParams!.sortOrder ?? 'Desc'
        }
        const pageNumber = initialParams!.pageNumber === undefined ? 1 : Number(initialParams!.pageNumber);
        const pageSize = initialParams!.pageSize === undefined ? 25 : Number(initialParams!.pageSize);

        setSortAndFilters(initialFilters);
        setPaginationPageNumber(pageNumber);
        setPaginationPageSize(pageSize);

        loadDevices(initialFilters, pageNumber, pageSize).catch();
        loadAlertsConfig().catch();
    }, []);

    const getSelectedDevices = () => {
        return filteredDevices.filter(device => selectedDevicesIds.includes(device.rentaId));
    }

    const handleOnGearButtonClick = (deviceId: string): void => {
        setIsSubscribeToAlertsModalOpen(true);
        setSelectedDevicesIds([deviceId]);
    }

    const handleOnDebugButtonClick = async (assetId: string, rentaGroupId: string, rentaId: string): Promise<string> => {
        try {
            return await getDeviceDebugDetailsAsync(assetId, rentaGroupId, rentaId);

        } catch (error) {
            console.error('Error loading device details:', error);
            throw error;
        }
    };

    const handleOnItemClick = (selectedDevicesIds: string[]): void => {
        setSelectedDevicesIds(selectedDevicesIds);
        setShowBottomSheet(selectedDevicesIds.length > 0);
    };

    const handleOnRequestReturnClick = () => {

    };

    const handleOnFilter = (filters: FleetMonitoringFilters) => {
        if (JSON.stringify(filters) === JSON.stringify(sortAndFilters)) {
            return;
        }

        setSortAndFilters(filters);
        setPaginationPageNumber(1);
        saveUserSelection(filters, 1, paginationPageSize);
        loadDevices(filters, 1, paginationPageSize).catch();
    }

    const handleOnPageNumberChange = (pageNumber: number) => {
        setPaginationPageNumber(pageNumber);
        saveUserSelection(sortAndFilters!, pageNumber, paginationPageSize);
        loadDevices(sortAndFilters!, pageNumber, paginationPageSize).catch();
    }

    const handleOnPageSizeChange = (pageSize: number) => {
        setPaginationPageSize(pageSize);
        saveUserSelection(sortAndFilters!, paginationPageNumber, pageSize);
        loadDevices(sortAndFilters!, paginationPageNumber, pageSize).catch();
    }

    const saveUserSelection = (filters: FleetMonitoringFilters, pageNumber: number, pageSize: number) => {
        const params = new FleetMonitoringFiltersAndPagination(
            filters.constructionSite,
            filters.constructionSiteId,
            filters.deviceName,
            filters.productGroup,
            filters.productGroupId,
            filters.company,
            filters.companyId,
            pageNumber,
            pageSize,
            filters.sortBy,
            filters.sortOrder
        );
        onFilterParamsChange(params);

        UserInteractionDataStorage.set(USER_DATA_STORAGE_KEY, params, DataStorageType.Page);
    };

    const loadDeviceDetails = async (assetId: string, rentaGroupId: string, rentaId: string): Promise<DeviceDetails> => {
        try {
            return await getDeviceDetailsAsync(assetId, rentaId, rentaGroupId);
        } catch (error) {
            console.error('Error loading device details:', error);
            throw error;
        }
    };

    const loadDevicesDetails = async (devices: DeviceDetailsRequest[]): Promise<DeviceDetails[]> => {
        try {
            return await getDevicesDetailsAsync(devices);
        } catch (error) {
            console.error('Error loading devices details:', error);
            throw error;
        }
    };

    const loadDevices = async (filters: FleetMonitoringFilters, pageNumber: number, pageSize: number) => {
        setIsLoading(true);

        try {
            // Map the string value to the enum value
            const sortDirectionEnum = SortDirection[filters.sortOrder as keyof typeof SortDirection];

            // Admin can filter devices from every organization and construction site. Other users have a specific role, so their search is narrowed.
            let constructionSiteId = (userRoleIsAdmin || !userRoleConstructionSiteId) ? undefined : userRoleConstructionSiteId;
            let contractId = (userRoleIsAdmin || !userRoleContractId) ? undefined : userRoleContractId;
            let company = filters.company;
            if (filters.companyId?.length) {
                contractId = filters.companyId;
                company = undefined;
            }
            let constructionSite = filters.constructionSite;
            if (filters.constructionSiteId?.length) {
                constructionSiteId = filters.constructionSiteId;
                constructionSite = undefined;
            }

            const filteredDevices: IPagedList<DeviceModel> = await getDevicesPagedListAsync(
                pageNumber,
                pageSize,
                filters.sortBy!,
                sortDirectionEnum,
                constructionSite,
                constructionSiteId,
                contractId,
                filters.deviceName,
                !filters.productGroupId ? filters.productGroup : undefined,
                filters.productGroupId,
                company
            );
            setFilteredDevices(filteredDevices.items);

            // Extract pagination properties.
            const { totalItemCount } = filteredDevices;
            setTotalItemCount(totalItemCount);

        } catch (error) {
            console.error('Error loading devices:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const loadAlertsConfig = async () => {
        try {
            const alertsConfig = await getAlertsConfig();
            setAlertsConfig(alertsConfig);
        } catch (error) {
            console.error('Error loading alerts config:', error);
        }
    };

    const removeDeletedAlarms = (deletedAlarms: IDeletedAlarm[]) => {
        // Ensure that the state updates are processed sequentially and the changes are not overwritten
        setFilteredDevices(prevFilteredDevices => {
            const updatedFilteredDevices = [...prevFilteredDevices];

            deletedAlarms.forEach(({ deviceId, alarmId }) => {
                const index = updatedFilteredDevices.findIndex(device => device.rentaId === deviceId);

                if (index !== -1) {
                    // If the device is found, merge the new alarm's configuredAlert with its existing configuredAlerts
                    updatedFilteredDevices[index] = {
                        ...updatedFilteredDevices[index],
                        configuredAlerts: updatedFilteredDevices[index].configuredAlerts.filter(alert => alert.id !== alarmId)
                    };
                }
            });

            return updatedFilteredDevices;
        });
    };

    const saveNewAlarms = (newAlarms: INewAlarm[]) => {
        // Ensure that the state updates are processed sequentially and the changes are not overwritten
        setFilteredDevices(prevFilteredDevices => {
            const updatedFilteredDevices = [...prevFilteredDevices];

            newAlarms.forEach(({ deviceId, configuredAlert }) => {
                const index = updatedFilteredDevices.findIndex(device => device.rentaId === deviceId);

                if (index !== -1) {
                    // If the device is found, merge the new alarm's configuredAlert with its existing configuredAlerts
                    updatedFilteredDevices[index] = {
                        ...updatedFilteredDevices[index],
                        configuredAlerts: [...updatedFilteredDevices[index].configuredAlerts, configuredAlert]
                    };
                }
            });

            return updatedFilteredDevices;
        });
    };

    return (
        <>
            <div className={styles.fleetMonitoring}>
                <div className={styles.titleArea}>
                    <div className={styles.container}>
                        <h1>{Localizer.fleetMonitoringPageTitle}</h1>
                    </div>
                </div>

                <div className={styles.devices}>
                    <div id="container" className={styles.container}>
                        {sortAndFilters && (
                            <ViewControl userRoleConstructionSiteId={userRoleConstructionSiteId}
                                         userRoleContractId={userRoleContractId}
                                         userRoleIsAdmin={userRoleIsAdmin}
                                         onFilterAndSort={handleOnFilter}
                                         filters={sortAndFilters}
                            />
                        )}

                        {isLoading ? (
                            <div style={{ width: '100%' }}>
                                <div className={styles.spinnerContainer}>
                                    <Spinner />
                                </div>
                            </div>
                        ) : (
                            <>
                                <DeviceGrid
                                    devices={() => filteredDevices}
                                    deviceDetails={(assetId: string, rentaGroupId: string, rentaId: string) => loadDeviceDetails(assetId, rentaGroupId, rentaId)}
                                    devicesDetails={(devices: DeviceDetailsRequest[]) => loadDevicesDetails(devices)}
                                    onGearButtonClick={(deviceId) => handleOnGearButtonClick(deviceId)}
                                    onDebugClick={userRoleIsAdmin ? (assetId: string, rentaGroupId: string, rentaId: string) => handleOnDebugButtonClick(assetId, rentaGroupId, rentaId) : undefined }
                                    onConstructionSiteLinkClick={async (constructionSiteId: string) => onConstructionSiteLinkClick(constructionSiteId)}
                                    onCompanyLinkClick={async (contractId: string) => onCompanyLinkClick(contractId)}
                                    onRowCheckboxClick={(selectedDevicesIds) => handleOnItemClick(selectedDevicesIds)}
                                />

                                <Pagination
                                    pageNumber={paginationPageNumber}
                                    pageSize={paginationPageSize}
                                    totalItemCount={totalItemCount}
                                    onPageNumberChange={(pageNumber: number) => handleOnPageNumberChange(pageNumber)}
                                    onPageSizeChange={(pageSize: number) => handleOnPageSizeChange(pageSize)}
                                />
                            </>
                        )}

                    </div>

                </div>

            </div>

            <DeviceBottomSheet
                isVisible={showBottomSheet}
                onAdjustAlertsClick={() => setIsSubscribeToAlertsModalOpen(true)}
                onRequestReturnClick={() => handleOnRequestReturnClick()}
            />

            <SubscribeToAlertsModal
                getSelectedDevices={() => getSelectedDevices()}
                saveNewAlarms={(newAlarms: INewAlarm[]) => saveNewAlarms(newAlarms)}
                removeDeletedAlarms={(deletedAlarms: IDeletedAlarm[]) => removeDeletedAlarms(deletedAlarms)}
                isOpen={isSubscribeToAlertsModalOpen}
                onClose={() => setIsSubscribeToAlertsModalOpen(false)}
                alertsConfig={alertsConfig}
            />
        </>
    );
};

export default FleetMonitoringContainer;
import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {ConstructionSiteEquipmentReturn} from "@/models/server/ConstructionSiteEquipmentReturn";
import {DailyRentalType, RentType} from "@/models/Enums";
import UserContext from "@/models/server/UserContext";
import {BaseAsyncComponent, ch, IBaseAsyncComponentState} from "@renta-apps/athenaeum-react-common";
import PriceHelper from "@/helpers/PriceHelper";
import EasyEquipment from "@/models/server/EasyEquipment";
import Localizer from "@/localization/Localizer";

import styles from "./EquipmentGroup.module.scss";
import {Button, ButtonType} from "@renta-apps/athenaeum-react-components";
import PageDefinitions from "@/providers/PageDefinitions";

interface IEquipmentGroupProps {
    equipmentGroup: EasyEquipment[];
    showPrices: boolean;
}

interface IEquipmentGroupState extends IBaseAsyncComponentState<EasyEquipment[]> {
}

export default class EquipmentGroup extends BaseAsyncComponent<IEquipmentGroupProps, IEquipmentGroupState, {}> {
    private get equipmentGroup(): EasyEquipment[] {
        return this.props.equipmentGroup;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get showPrices(): boolean {
        return this.props.showPrices;
    }

    private renderEquipment(equipment: EasyEquipment) {
        return (
            <div id="equipmentGroupItem">
                <div className={styles.item}>
                    <span><b>{Localizer.constructionDetailsContract}:</b></span>
                    <span>{equipment.contractId} {equipment.constructionSiteDisplayName}</span>

                    <span><b>{Localizer.constructionSiteDetailsProductsAvailableForReturn}:</b></span>
                    <span>{equipment.count}</span>

                    {
                        this.showPrices && (
                            <>
                                <span>
                                    <b>{Localizer.constructionSiteDetailsEquipmentTotalPrice}: </b>
                                </span>
                                {
                                    (equipment.rentType === RentType.Daily &&
                                        <span>
                                            <span className={styles.price}>
                                                {Utility.format(`{0:C} ${PriceHelper.environmentCurrencyCode}`, equipment.dailyPrice)}
                                            </span>
                                            <span> / {Localizer.genericDay} {((equipment!.dailyRentalType != null)
                                                && (equipment.dailyRentalType === DailyRentalType.FiveDayRent)) ?
                                                Localizer.enumDailyRentalTypeFiveDayRent :
                                                equipment.dailyRentalType === DailyRentalType.SixDayRent ?
                                                    Localizer.enumDailyRentalTypeSixDayRent :
                                                    Localizer.enumDailyRentalTypeSevenDayRent}
                                            </span>
                                        </span>
                                    )
                                }

                                {
                                    (equipment.rentType === RentType.Monthly &&
                                        <span>
                                            <span className={styles.price}>
                                                {Utility.format(`{0:C} ${PriceHelper.environmentCurrencyCode}`, equipment.monthlyUnitPrice)}
                                            </span>
                                            <span> / {Localizer.genericMonth} </span>
                                        </span>
                                    )
                                }
                            </>
                        )
                    }

                    <span><b>{Localizer.constructionSiteDetailsEquipmentOrderedBy}:</b></span>
                    <span>{equipment.renter?.length ? equipment.renter : Localizer.constructionSiteDetailsEquipmentNotAvailable}</span>
                    <span><b>{Localizer.constructionSiteDetailsEquipmentRentalPeriod}:</b></span>
                    <span>{equipment.rentDate && Utility.toDateString(equipment.rentDate)} - {equipment.returnedDate && Utility.toDateString(equipment.returnedDate)}</span>
                    <span><b>{Localizer.constructionSiteDetailsEquipmentReturned}:</b></span>
                    <div>
                    {
                        equipment.returns.map((returnItem: ConstructionSiteEquipmentReturn, index: number) => (
                            <div key={index}>
                                {`${Utility.toDateString(returnItem!.returnedDate!)} - ${returnItem.returnedCount}`}
                            </div>
                        ))
                    }
                    </div>
                    <span><b>{Localizer.shoppingCartPageLocation}:</b></span>
                    <span>{equipment.depotName}</span>
                </div>
                {
                    (equipment.rentaId && (!equipment.isReturned || this.userContext.isAdmin)) && (
                        <div className={styles.infoContainer}>
                            <Button icon={{name: equipment.trackunitSerialNumber ? "map-marker-alt" : "info-circle"}}
                                    type={ButtonType.Default}
                                    className={styles.infoButton}
                                    route={PageDefinitions.productLocation.route({
                                        params: {
                                            trackingSerialNumber: equipment.trackunitSerialNumber,
                                            rentaId: equipment.rentaId,
                                            rentalObjectId: equipment.rentalObjectId
                                        }
                                    })}
                                    label={(equipment.trackunitSerialNumber) ? Localizer.equipmentLocationAndDetails : Localizer.equipmentDetails}
                            />
                            <Button icon={{name: "file"}}
                                    type={ButtonType.Default}
                                    className={styles.infoButton}
                                    route={PageDefinitions.equipmentDocuments.route({
                                        params: {
                                            rentaId: equipment.rentaId,
                                            rentalObjectId: equipment.rentalObjectId
                                        }
                                    })}
                                    label={Localizer.constructionsiteEquipmentDocuments}
                            />
                        </div>
                    )
                }
            </div>
        );
    }

    protected getEndpoint(): string {
        return "";
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(styles.equipmentGroup)}>
                <div className={styles.container}>
                    {
                        this.equipmentGroup.map((equipment: EasyEquipment, index: number) => (

                            <React.Fragment key={index}>
                                {this.renderEquipment(equipment)}

                                {(this.equipmentGroup.length > 0 && index !== this.equipmentGroup.length - 1) && <hr className={styles.separator}/>}
                            </React.Fragment>

                        ))
                    }
                </div>
            </div>
        );
    }

}
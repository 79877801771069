import React from "react";
import {
    Accordion,
    Button,
    ButtonType,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    EmailInput,
    EmailValidator,
    Form,
    Icon,
    IconSize,
    Modal,
    OneColumn,
    SelectListGroup,
    SelectListItem,
    TextInput,
    TwoColumns
} from "@renta-apps/athenaeum-react-components";
import {AlertModel, BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {ArrayUtility, DateUtility, FileModel, SortDirection, Utility,} from "@renta-apps/athenaeum-toolkit";
import {ConstructionSiteFilterRentedOrReturned, ConstructionSiteSchedules, EquipmentSortByType, EquipmentStatus, RentType, ServiceType, TogglerPosition} from "@/models/Enums";
import {SaveConstructionSiteScheduleRequest} from "@/models/server/SaveConstructionSiteScheduleRequest";
import {ConstructionSiteScheduleModel} from "@/models/server/ConstructionSiteScheduleModel";
import {ConstructionSiteScheduleData} from "@/models/server/ConstructionSiteScheduleData";
import {ConstructionSiteEquipment} from "@/models/server/ConstructionSiteEquipment";
import {ConstructionSiteFilters} from "@/models/server/ConstructionSiteFilters";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import EnumProvider from "@/providers/EnumProvider";
import CreateConstructionSiteServiceRequestRequest from "@/models/server/Requests/CreateConstructionSiteServiceRequestRequest";
import TransformProvider from "@/providers/TransformProvider";
import UserModel from "@/models/server/UserModel";
import UserContext from "@/models/server/UserContext";
import Equipment from "@/pages/ConstructionSiteDetails/Equipment/Equipment";
import ConstructionSiteItemsGrouped from "@/pages/ConstructionSiteDetails/Models/ConstructionSiteItemsGrouped";
import EquipmentToolbar from "@/pages/ConstructionSiteDetails/EquipmentToolbar/EquipmentToolbar";
import EquipmentToolbarModel from "@/pages/ConstructionSiteDetails/EquipmentToolbar/EquipmentToolbarModel";
import SummaryRow from "@/pages/ConstructionSiteDetails/Models/SummaryRow";
import ConstructionSiteReturn from "@/models/server/ConstructionSiteReturn";
import PriceHelper from "@/helpers/PriceHelper";
import Localizer from "@/localization/Localizer";
import styles from "./Equipments.module.scss";
import EasyEquipment from "@/models/server/EasyEquipment";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import LoadingOrDataNotFound from "@/components/LoadingOrDataNotFound/LoadingOrDataNotFound";
import InviteNewUserRequest from "@/models/server/Requests/InviteNewUserRequest";
import RentaEasyController from "@/pages/RentaEasyController";
import {ContractConstructionSiteUserModel} from "@/models/server/ContractConstructionSiteUserModel";
import ReturnProductForm from "@/pages/ConstructionSiteDetails/Equipments/ReturnProductForm";
import {IEquipmentFiltersData, IEquipmentPageData, IEquipmentRequest, IResponse, ISendSummaryRequest} from "@/pages/ConstructionSiteDetails/Equipments/types";
import {getConstructionSiteEquipment, getProductEquipment, getServiceRequestDefinitionId} from "@/services/ConstructionSiteService";
import ServiceRequestModal from "@/pages/ConstructionSiteDetails/Equipments/ServiceRequestModal";

export class EasyCategoryModel {
    public name: string = "";

    public pictureReference: string = "";

    public parentId: string | null = null;

    public id: string = "";

    public hidden: boolean = false;

    public productGroupIds: string[] = [];

    public priority: number = 0;
}

interface IProductsState {
    inviteUsers: SelectListItem[] | null;
    mySchedules: ConstructionSiteScheduleModel[];
    serviceRequest: CreateConstructionSiteServiceRequestRequest | null;
    model: EquipmentToolbarModel;
    returnProducts: ConstructionSiteReturn[];
    equipments: EasyEquipment[];
    selectedEquipment: EasyEquipment | null;
    filteredEquipment: ConstructionSiteItemsGrouped[];
    equipmentFiltersData: IEquipmentFiltersData | null;
    constructionSite: ConstructionSiteModel | null;
    showPrices: boolean;
    canOrderServices: boolean;
    alertModel: AlertModel | null;
    isLoading: boolean;
    temporaryContract: OrganizationContractModel | null;
    equipmentOrderByValue: number;
    inviteUserRequest: InviteNewUserRequest | null;
    inviteUserResponse: IResponse | null;
    receiverEmail: string;
}

interface IEquipmentPageProps {
    equipments?: ConstructionSiteEquipment[],
    noConstructionSite?: boolean,
    constructionSiteId?: string,
    contractId?: string,
    equipmentOnly?: boolean

    serviceCreated?(): Promise<void>;
}

export class GroupedSummaryRow {
    public name: string | null = null;

    public rows: SummaryRow[] = [];

    constructor(name: string, rows: SummaryRow[]) {
        this.name = name;
        this.rows = rows;
    }
}

export default class Equipments extends BaseComponent<IEquipmentPageProps, IProductsState> {

    // Fields

    public state: IProductsState = {
        inviteUsers: null,
        mySchedules: [],
        model: new EquipmentToolbarModel(),
        serviceRequest: null,
        returnProducts: [],
        equipments: [],
        selectedEquipment: null,
        filteredEquipment: [],
        equipmentFiltersData: null,
        constructionSite: null,
        showPrices: false,
        canOrderServices: false,
        alertModel: null,
        isLoading: true,
        temporaryContract: null,
        equipmentOrderByValue: EquipmentSortByType.Category,
        inviteUserRequest: null,
        inviteUserResponse: null,
        receiverEmail: this.user?.email ?? ""
    };

    private readonly _sendSummaryFormRef: React.RefObject<Form> = React.createRef();
    private readonly _serviceRequestModal: React.RefObject<Modal> = React.createRef();
    private readonly _schedulerInfoModal: React.RefObject<Modal> = React.createRef();
    private readonly _returnItemsAccordionRef: React.RefObject<Accordion> = React.createRef();

    // Properties

    private get hasConstructionSite(): boolean {
        return !!this.state.constructionSite;
    }

    private get showPrices(): boolean {
        return this.state.showPrices;
    }

    private get canOrderServices(): boolean {
        return this.state.canOrderServices;
    }

    private get siteUsers(): SelectListItem[] {
        return this.state.inviteUsers ?? [];
    }

    private get canReturnProducts(): boolean {
        if (this.noConstructionSite) {
            if (!this.props.equipmentOnly) {
                return false;
            }
        }
        return true;
    }

    private get constructionSite(): ConstructionSiteModel {
        return this.state.constructionSite!;
    }

    private get user(): UserModel | null {
        return this.userContext.user;
    }

    private get equipmentFiltersData(): IEquipmentFiltersData | null {
        return this.state.equipmentFiltersData;
    }

    private get filters(): EquipmentToolbarModel {
        return this.state.model;
    }

    private get noConstructionSite(): boolean {
        return this.props.noConstructionSite ?? false;
    }

    /**
     * Contract the (Renta) user is making an return for.
     */
    private get temporaryContract(): OrganizationContractModel | null {
        return this.state.temporaryContract;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get mySchedules(): ConstructionSiteScheduleModel[] {
        return this.state.mySchedules;
    }

    public get desktop(): boolean {
        return (window.innerWidth >= RentaEasyConstants.bootstrapBreakdownWidth);
    }

    public get inviteUserRequest(): InviteNewUserRequest | null {
        return this.state.inviteUserRequest;
    }

    private get isSummaryFormValid(): boolean {
        return this.state.receiverEmail.length > 0 && EmailValidator.instance.validate(this.state.receiverEmail) === null;
    }

    // Methods

    private static getMonthlyIncome(equipment: ConstructionSiteItemsGrouped[]): number {
        const filteredEquipment: ConstructionSiteEquipment[] = equipment.flatMap(item => item.items);

        return filteredEquipment.where(x => x.status === EquipmentStatus.Rented).map(item => item.combinedMonthlyPrice || 0).reduce((cur, prev) => cur + prev, 0);
    }

    private static getDailyIncome(equipment: ConstructionSiteItemsGrouped[]): number {
        const filteredEquipment: ConstructionSiteEquipment[] = equipment.flatMap(item => item.items);

        return filteredEquipment.where(x => x.status === EquipmentStatus.Rented).map(item => item.combinedDailyPrice || 0).reduce((cur, prev) => cur + prev, 0);
    }

    private static getScheduleRuleTest(rule: ConstructionSiteSchedules): string {
        switch (rule) {
            case ConstructionSiteSchedules.Daily:
                return Localizer.schedulerDailyRuleName;
            case ConstructionSiteSchedules.Weekly:
                return Localizer.schedulerWeeklyRuleName;
            case ConstructionSiteSchedules.Monthly:
                return Localizer.schedulerMonthlyRuleName;
            default:
                return "";
        }
    }

    private static getRentTypesText(rentTypes: number[]) {
        let daily: number | undefined = rentTypes.find(a => a === 0);
        let monthly: number | undefined = rentTypes.find(a => a === 2);

        return (typeof daily === "number")
            ? Localizer.constructionSiteDetailsRentTypeFilterDailyOption
            : (typeof monthly === "number")
                ? Localizer.constructionSiteDetailsRentTypeFilterMonthlyOption
                : Localizer.constructionSiteDetailsRentTypeFilterNothingSelectedText;
    }

    private async getMySchedules(constructionSiteId: string): Promise<ConstructionSiteScheduleModel[]> {
        return await this.postAsync("/api/ConstructionSites/GetSchedules", constructionSiteId);
    }

    private getFilteredEquipment(equipment: EasyEquipment[], filters: EquipmentToolbarModel, filtersData: IEquipmentFiltersData): ConstructionSiteItemsGrouped[] {
        let filteredEquipment = equipment;

        if (filters.searchQuery) {
            filteredEquipment = filteredEquipment.filter(item => Object.values(item)
                .some(value => value?.toString().toLowerCase().includes(filters.searchQuery.toLowerCase())));
        }
        if (filters.contracts.length) {
            filteredEquipment = filteredEquipment.filter(item => filters.contracts.includes(item.contractId));
        }
        if (filters.renters.length) {
            filteredEquipment = filteredEquipment.filter(item => filters.renters.includes(item.renter));
        }
        if (filters.products.length) {
            filteredEquipment = filteredEquipment.filter(item => filters.products.includes(item.inventoryItemNumber));
        }
        if (filters.categories.length) {
            filteredEquipment = filteredEquipment.filter(item => filters.categories.includes(item.rentalObjectNumber));
        }
        if (filters.rentType !== null) {
            filteredEquipment = filteredEquipment.filter(item => item.rentType === filters.rentType);
        }
        if (filters.status !== null) {
            filteredEquipment = filteredEquipment.filter(item => item.status === filters.status);
        }

        if (filters.depos.length) {
            filteredEquipment = filteredEquipment.filter(item => filters.depos.includes(item.location!.toString()));
        }

        if (filters.rentedOrReturnedStart !== null) {
            filteredEquipment = filteredEquipment.filter(item => (item.rentDate && DateUtility.compare(item.rentDate!, filters.rentedOrReturnedStart!) >= 0)
                || (item.returnedDate && DateUtility.compare(item.returnedDate!, filters.rentedOrReturnedStart!) >= 0));

            if (filters.rentedOrReturnedEnd !== null) {
                filteredEquipment = filteredEquipment.filter(item => (item.rentDate && DateUtility.compare(filters.rentedOrReturnedEnd!, item.rentDate!) >= 0)
                    || (item.returnedDate && DateUtility.compare(filters.rentedOrReturnedEnd!, item.returnedDate!) >= 0));
            }
        }

        if (filters.rentaFuture !== null) {
            filteredEquipment = filteredEquipment
                .filter(item => item.isRentaFuture === filters.rentaFuture);
        }

        const dateNow = new Date();
        let pastWeek = new Date(dateNow);
        pastWeek.setDate(pastWeek.getDate() - 7);
        let past2Week = new Date(dateNow);
        past2Week.setDate(past2Week.getDate() - 7 * 2);

        let pastMonth = new Date(dateNow);
        pastMonth.setDate(pastMonth.getDate() - 30);

        if (filters.rentedOrReturnedAt !== null) {
            if (filters.rentedOrReturnedAt === ConstructionSiteFilterRentedOrReturned.PastWeek) {
                filteredEquipment = filteredEquipment.filter(item => (item.rentDate && DateUtility.compare(item.rentDate!, pastWeek) >= 0)
                    || (item.returnedDate && DateUtility.compare(item.returnedDate!, pastWeek) >= 0));
            }
            if (filters.rentedOrReturnedAt === ConstructionSiteFilterRentedOrReturned.PastTwoWeeks) {
                filteredEquipment = filteredEquipment.filter(item => (item.rentDate && DateUtility.compare(item.rentDate!, past2Week) >= 0)
                    || (item.returnedDate && DateUtility.compare(item.returnedDate!, past2Week) >= 0));

            }
            if (filters.rentedOrReturnedAt === ConstructionSiteFilterRentedOrReturned.PastMonth) {
                filteredEquipment = filteredEquipment.filter(item => (item.rentDate && DateUtility.compare(item.rentDate!, pastMonth) >= 0)
                    || (item.returnedDate && DateUtility.compare(item.returnedDate!, pastMonth) >= 0));
            }
        }

        let sortByProperty: string = (filters.sortBy === EquipmentSortByType.Name) ? "name" : "combinedCount";
        let sortDirection: SortDirection = (filters.sortBy === EquipmentSortByType.AmountDesc)
            ? SortDirection.Desc
            : SortDirection.Asc;

        if (filters.sortBy === EquipmentSortByType.ContractIdAsc || filters.sortBy === EquipmentSortByType.ContractIdDesc) {
            sortByProperty = "contractId";
            sortDirection = (filters.sortBy === EquipmentSortByType.ContractIdDesc)
                ? SortDirection.Desc
                : SortDirection.Asc;
        }

        if (filters.sortBy === EquipmentSortByType.OrdererAsc || filters.sortBy === EquipmentSortByType.OrdererDesc) {
            sortByProperty = "renter";
            sortDirection = (filters.sortBy === EquipmentSortByType.OrdererDesc)
                ? SortDirection.Desc
                : SortDirection.Asc;
        }

        if (filters.sortBy === EquipmentSortByType.RentalEndDateAsc || filters.sortBy === EquipmentSortByType.RentalEndDateDesc) {
            sortByProperty = "returnedDate";
            sortDirection = (filters.sortBy === EquipmentSortByType.RentalEndDateDesc)
                ? SortDirection.Desc
                : SortDirection.Asc;
        }

        if (filters.sortBy === EquipmentSortByType.RentalStartDateAsc || filters.sortBy === EquipmentSortByType.RentalStartDateDesc) {
            sortByProperty = "rentDate";
            sortDirection = (filters.sortBy === EquipmentSortByType.RentalStartDateDesc)
                ? SortDirection.Desc
                : SortDirection.Asc;
        }

        if (filters.sortBy === EquipmentSortByType.Category) {

            let resultList: ConstructionSiteItemsGrouped[] = [];
            const categories: SelectListItem[] = filtersData.categories.sort(ArrayUtility.sortByProperty("text", SortDirection.Asc));

            if (categories && categories.length > 0) {

                for (let i = 0; i < categories.length; i++) {
                    const groupItems: EasyEquipment[] = filteredEquipment
                        .where(a => !!a.rentalObjectNumber)
                        .filter(a => categories[i].value.split("|").includes(a.rentalObjectNumber));

                    if (groupItems.length > 0) {
                        const item: ConstructionSiteItemsGrouped = {
                            items: groupItems,
                            name: categories[i].text
                        };

                        resultList.push(item);
                    }
                }

                const noCategoryEquipments: EasyEquipment[] = filteredEquipment
                    .filter(a => !resultList.some(b => b.items.includes(a)));

                const item: ConstructionSiteItemsGrouped = {
                    items: noCategoryEquipments,
                    name: Localizer.constructionSiteDetailsUndefinedEquipment
                };

                resultList.push(item);

            }
            else {
                filteredEquipment.sort(ArrayUtility.sortByProperty("externalProductGroupId", sortDirection));
                let flatArray: ConstructionSiteItemsGrouped[] = [];
                let item: ConstructionSiteItemsGrouped =
                    {
                        name: "",
                        items: filteredEquipment
                    };
                flatArray.push(item);

                return flatArray;
            }

            return resultList;
        }

        filteredEquipment.sort(ArrayUtility.sortByProperty(sortByProperty, sortDirection));

        let flatArray: ConstructionSiteItemsGrouped[] = [];
        let item: ConstructionSiteItemsGrouped =
            {
                name: "",
                items: filteredEquipment
            };

        flatArray.push(item);

        return flatArray;
    }

    private getProductsText(products: string[]) {
        return (this.equipmentFiltersData) && (products.length > 0)
            ? this
                .equipmentFiltersData
                .products
                .filter((item: SelectListItem) => products
                    .some((value: string) => item
                        .value
                        .split("|")
                        .includes(value)))
                .map(a => a.text)
            : [];
    }

    private getDepotsText(depots: string[]) {
        return (this.equipmentFiltersData) && (depots.length)
            ? this
                .equipmentFiltersData
                .depotItems
                .filter((item: SelectListItem) => depots
                    .some((value: string) => item
                        .value
                        .includes(value)))
                .map(a => a.text)
            : [];
    }

    private getCategoriesText(categories: string[]) {
        return (this.equipmentFiltersData) && (categories.length)
            ? this
                .equipmentFiltersData
                .categories
                .filter((item: SelectListItem) => categories
                    .some((value: string) => item
                        .value
                        .split("|")
                        .includes(value)))
                .map(a => a.text)
            : [];
    }

    private async getEquipmentAsync(): Promise<IEquipmentPageData> {
        const contractId: string | null = this.props.contractId ?? this.userContext.selectedContractId;
        const constructionSiteId: string | null = this.props.constructionSiteId ?? null;

        if (this.noConstructionSite && contractId) {
            const showOnlyRented: boolean = this.props.equipmentOnly ?? true;
            const request: IEquipmentRequest = {
                customerId: contractId,
                equipmentOnly: showOnlyRented,
            };

            // Set initial status as null if we're showing all company rented equipment, so status filter is in "Active & closed" state
            const model: EquipmentToolbarModel = this.state.model;
            model.status = showOnlyRented ? EquipmentStatus.Rented : null;
            await this.setState({model: model});

            return await getProductEquipment(request);
        }

        return await getConstructionSiteEquipment(constructionSiteId);
    }


    private async openServiceRequestModalAsync(serviceType: ServiceType, location: number | null, deviceName: string, deviceInventoryItemNumber: string): Promise<void> {

        const serviceRequestDefinitionId: string = await getServiceRequestDefinitionId(serviceType);

        const request: CreateConstructionSiteServiceRequestRequest = {
            serviceRequestDefinitionId: serviceRequestDefinitionId,
            type: serviceType,
            message: null,
            constructionSiteId: this.constructionSite.id,
            deviceExternalId: deviceInventoryItemNumber,
            depotExternalId: location ?? 0,
            deviceName: deviceName,
            images: null,
        };

        this.setState({
            serviceRequest: request
        });

        if (this._serviceRequestModal.current) {
            await this._serviceRequestModal.current.openAsync();
        }
    }

    private async openSchedulerInfoModalAsync(): Promise<void> {
        if (this._schedulerInfoModal.current) {
            await this._schedulerInfoModal.current.openAsync();
        }
    }

    private async filterEquipmentAsync(model: EquipmentToolbarModel): Promise<void> {
        const filteredEquipment: ConstructionSiteItemsGrouped[] = this.getFilteredEquipment(this.state.equipments, model, this.state.equipmentFiltersData!);
        this.setState({filteredEquipment, model});
    }

    private async setSearchQueryAsync(value: string): Promise<void> {
        if (this.filters.searchQuery !== value) {
            this.filters.searchQuery = value;
            await this.filterEquipmentAsync(this.filters);
        }
    }

    private async setSortByTypeAsync(item: SelectListItem | null): Promise<void> {
        // dropdown with required=true will not return null value.
        if (!item) {
            return;
        }
        const value: EquipmentSortByType = Number.parseInt(item.value);

        this.setState({equipmentOrderByValue: value});

        if (this.filters.sortBy !== value) {
            this.filters.sortBy = value;
            await this.filterEquipmentAsync(this.filters);
        }
    }

    private async onAmountChangeHandlerAsync(model: ConstructionSiteReturn): Promise<void> {
        const returnProducts: ConstructionSiteReturn[] = this.state.returnProducts;
        const match = (item: ConstructionSiteReturn): boolean => (item.rentaId === model.rentaId) && (item.name === model.name) && (item.contractNumber === model.contractNumber);

        const existingModel: ConstructionSiteReturn | undefined = returnProducts.find(match);

        if (existingModel) {
            const newCount: number = model.count;
            const index: number = returnProducts.findIndex(match);

            if (newCount) {
                existingModel.count = newCount;

                returnProducts[index] = existingModel;
            }
            else {
                returnProducts.removeAt(index);
            }

        }
        else {
            returnProducts.push(model);
        }

        await this.setState({returnProducts});
    }

    private async scheduleSummaryAsync(title: string, scheduleType: ConstructionSiteSchedules): Promise<void> {
        const summeryForm: Form | null = this._sendSummaryFormRef.current;
        if (summeryForm) {
            const receiver: string = this.state.receiverEmail;

            const scheduleRule: ConstructionSiteSchedules = scheduleType;

            const selectedCategories: string[] = this.filters.categories;

            const selectedProducts: string[] = this.filters.products;

            const selectedContracts: string[] = this.filters.contracts;

            const selectedRenters: string[] = this.filters.renters;

            const selectedDepos: string[] = this.filters.depos;

            const rentType: number[] = this.filters.rentType !== null
                ? [this.filters.rentType]
                : [];

            const status: number[] = this.filters.status !== null
                ? [this.filters.status]
                : [];

            const rentedOrReturnedAt: number | null = this.filters.rentedOrReturnedAt !== null
                ? this.filters.rentedOrReturnedAt
                : null;

            const isRentaFuture: boolean | null = this.filters.rentaFuture !== null
                ? this.filters.rentaFuture
                : null;

            const request: SaveConstructionSiteScheduleRequest = {
                constructionSiteId: this.constructionSite!.id,
                email: receiver,
                enabled: true,
                filters: this.equipmentFiltersData ?
                    {
                        categories: selectedCategories,
                        products: selectedProducts,
                        contracts: selectedContracts,
                        renters: selectedRenters,
                        depots: selectedDepos,
                        rentTypes: rentType,
                        statuses: status,
                        rentedOrReturnedAt: rentedOrReturnedAt,
                        isRentaFuture: isRentaFuture,
                    }
                    : null,
                scheduleRule: scheduleRule,
                title: title,
                equipmentOrderBy: this.state.equipmentOrderByValue
            };

            if (receiver && this.hasConstructionSite) {
                const response: ConstructionSiteScheduleModel = await this.postAsync("/api/ConstructionSites/ScheduleEmailTableData", request);

                this.mySchedules.push(response);

                this.reRender();
            }
        }
    }

    private constructionSiteName(): string | null {
        return !this.noConstructionSite && this.constructionSite.displayName
            ? this.constructionSite!.displayName
            : "";
    }

    private constructionSiteOwner(): string | null {
        return !this.noConstructionSite && this.constructionSite?.ownerAdditionalName
            ? `${this.constructionSite.ownerName} (${this.constructionSite.ownerAdditionalName})`
            : this.constructionSite?.ownerName;
    }

    private async downloadCsvAsync(grouped: ConstructionSiteItemsGrouped[]): Promise<void> {
        const equipment = grouped.flatMap(a => a.items);
        const request: ISendSummaryRequest = {
            productData: {
                rows: equipment.map((equipment: ConstructionSiteEquipment) => new SummaryRow(
                        ((equipment.combinedCount) ? equipment.combinedCount : 0),
                        equipment.name,
                        equipment.inventoryItemDisplayNumber,
                        equipment.returnedDate,
                        equipment.renter,
                        equipment.rentalObjectId,
                        (equipment.rentType === RentType.Monthly)
                            ? (equipment.combinedMonthlyPrice ?? 0)
                            : (equipment.combinedDailyPrice ?? 0),
                        equipment.rentType
                    )
                ),
                groupedRows: null
            },
            equipmentOrderBy: this.state.equipmentOrderByValue,
            constructionSiteOwner: this.constructionSiteOwner(),
            constructionSiteName: this.constructionSiteName(),
        };

        const response: FileModel = await this.postAsync("/api/ConstructionSites/DownloadTableData", request);

        ch.download(response);
    }

    private async sendSummaryAsync(grouped: ConstructionSiteItemsGrouped[]): Promise<void> {
        const receiver: string = this.state.receiverEmail;

        const groupedRows = grouped.map(item => new GroupedSummaryRow(
            item.name,
            item.items.map((equipment: ConstructionSiteEquipment) => new SummaryRow(
                ((equipment.combinedCount) ? equipment.combinedCount : 0),
                equipment.name,
                equipment.inventoryItemDisplayNumber,
                equipment.returnedDate,
                equipment.renter,
                equipment.rentalObjectId
            ))
        ));

        const request: ISendSummaryRequest = {
            productData: {
                groupedRows: groupedRows,
                rows: null
            },
            email: receiver,
            constructionSiteId: this.state.constructionSite ? this.state.constructionSite.id : null,
            equipmentOrderBy: this.state.equipmentOrderByValue
        };

        if (receiver) {
            await this.setState({isLoading: true});

            await this.postAsync("/api/ConstructionSites/EmailTableData", request);

            await this.setState({isLoading: false});
        }
    }

    private async deleteScheduleAsync(data: ConstructionSiteScheduleModel, index: number) {
        this.mySchedules.splice(index, 1);

        if (data.id) {
            await this.postAsync("/api/ConstructionSites/DeleteSchedule", data.id);
            this.reRender();
        }
    }

    private createEquipmentFiltersAsync(products: ConstructionSiteEquipment[], categories: EasyCategoryModel[]): IEquipmentFiltersData {

        if (!products || !categories) {
            return {} as IEquipmentFiltersData;
        }

        let productExternalIds: string[] = products
            .where(x => !!x.rentalObjectNumber)
            .map(x => x.rentalObjectNumber);

        let filteredCategories: EasyCategoryModel[] = categories
            .where(row => row.productGroupIds
                .some(id => productExternalIds.includes(id)));

        filteredCategories.order(row => row.name);

        let categoriesFilter: SelectListItem[] = filteredCategories
            .where(row => row.productGroupIds.length > 0)
            .map(item => {
                let listItem: SelectListItem = new SelectListItem();
                let groupItem: SelectListGroup = new SelectListGroup();
                groupItem.name = categories.find(x => x.id === item.parentId)?.name ?? "";
                listItem.text = item.name;
                listItem.value = item.productGroupIds.where(id => !!id).join("|");
                listItem.group = groupItem;

                return listItem;
            });

        categoriesFilter.order(x => x.group?.name, x => x.text);

        let orderedDistinctProducts = products.distinct(x => x.inventoryItemNumber);
        orderedDistinctProducts.order(x => x.name);

        let productsFilter: SelectListItem[] = orderedDistinctProducts
            .map(item => {
                let listItem = new SelectListItem();
                listItem.text = `${item.name} (${item.inventoryItemDisplayNumber})`;
                listItem.value = item.inventoryItemNumber;
                return listItem;
            });

        let dailyFilter = new SelectListItem();
        dailyFilter.text = Localizer.constructionSiteDetailsRentTypeFilterDailyOptionLanguageItemName;
        dailyFilter.value = RentType.Daily.toString();

        let monthlyFilter = new SelectListItem();
        monthlyFilter.text = Localizer.constructionSiteDetailsRentTypeFilterMonthlyOptionLanguageItemName;
        monthlyFilter.value = RentType.Monthly.toString();

        let rentalTypeFilter: SelectListItem[] = [dailyFilter, monthlyFilter];

        let contractNumberFilter: SelectListItem[] = products.map(row => row.contractId)
            .distinct(contractId => contractId)
            .map(item => {
                let listItem = new SelectListItem();
                listItem.text = item;
                listItem.value = item;
                return listItem;
            });

        contractNumberFilter.order(contractId => contractId);

        let rentedByFilter: SelectListItem[] = products.distinct(row => row.renter)
            .where(row => !!row.renter && row.renter !== "" && row.renter !== '')
            .map(item => item.renter)
            .map(item => {
                let listItem = new SelectListItem();
                listItem.text = item;
                listItem.value = item;
                return listItem;
            });

        let depotItems: SelectListItem[] = products
            .where(row => row.location !== null)
            .distinct(row => row.location)
            .map(item => {
                let listItem = new SelectListItem();
                listItem.text = item.depotName!;
                listItem.value = item.location!.toString();
                return listItem;
            });

        let activeFilter = new SelectListItem();
        activeFilter.text = Localizer.constructionSiteDetailsStateFilterActiveOptionLanguageItemName;
        activeFilter.value = EquipmentStatus.Rented.toString();

        let returnedFilter = new SelectListItem();
        returnedFilter.text = Localizer.constructionSiteDetailsStateFilterReturnedOptionLanguageItemName;
        returnedFilter.value = EquipmentStatus.Returned.toString();

        let statusFilter: SelectListItem[] = [activeFilter, returnedFilter];

        let pastWeekFilter = new SelectListItem();
        pastWeekFilter.text = Localizer.enumConstructionSiteFilterRentedOrReturnedPastWeekLanguageItemName;
        pastWeekFilter.value = ConstructionSiteFilterRentedOrReturned.PastWeek.toString();

        let pastTwoWeeksFilter = new SelectListItem();
        pastTwoWeeksFilter.text = Localizer.enumConstructionSiteFilterRentedOrReturnedPastTwoWeeksLanguageItemName;
        pastTwoWeeksFilter.value = ConstructionSiteFilterRentedOrReturned.PastTwoWeeks.toString();

        let pastMonthFilter = new SelectListItem();
        pastMonthFilter.text = Localizer.enumConstructionSiteFilterRentedOrReturnedPastMonthLanguageItemName;
        pastMonthFilter.value = ConstructionSiteFilterRentedOrReturned.PastMonth.toString();

        let rentedOrReturnedAt: SelectListItem[] = [pastWeekFilter, pastTwoWeeksFilter, pastMonthFilter];

        let isRentaFuture = new SelectListItem();
        isRentaFuture.text = Localizer.yes;
        isRentaFuture.value = true.toString();

        let notRentaFuture = new SelectListItem();
        notRentaFuture.text = Localizer.no;
        notRentaFuture.value = false.toString();

        let isRentaFutureFilter: SelectListItem[] = [isRentaFuture, notRentaFuture];

        return {
            categories: categoriesFilter,
            products: productsFilter,
            rentTypes: rentalTypeFilter,
            contracts: contractNumberFilter,
            renters: rentedByFilter,
            statuses: statusFilter,
            rentedOrReturnedItems: rentedOrReturnedAt,
            depotItems: depotItems,
            rentaFuture: isRentaFutureFilter
        } as IEquipmentFiltersData;
    }

    // Renders

    private renderScheduleFilters(filters: ConstructionSiteFilters | null): React.ReactNode {
        let statusesText: string = Localizer.constructionSiteDetailsScheduleFiltersAllStatuses;
        let rentTypesText: string = Localizer.constructionSiteDetailsScheduleFiltersAllRentTypes;
        let rentersText: string = Localizer.constructionSiteDetailsScheduleFiltersAllRenters;
        let categoriesText: string = Localizer.constructionSiteDetailsScheduleFiltersAllCategories;
        let contractsText: string = Localizer.constructionSiteDetailsScheduleFiltersAllContracts;
        let productsText: string = Localizer.constructionSiteDetailsScheduleFiltersAllProducts;
        let deposText: string = Localizer.constructionSiteDetailsScheduleFiltersAllProducts;
        let rentaFutureText: string = Localizer.genericAll;

        if (filters!.statuses.length > 0) {
            statusesText = filters!.statuses.map(value => Localizer.get(EnumProvider.getEquipmentStatusName(value))).join();
        }
        if (filters!.rentTypes.length > 0) {
            rentTypesText = Equipments.getRentTypesText(filters!.rentTypes);
        }
        if (filters!.renters.length > 0) {
            rentersText = filters!.renters.join();
        }
        if (filters?.depots && filters!.depots.length > 0) {
            deposText = this.getDepotsText(filters!.depots).join();
        }
        if (filters!.categories.length > 0) {
            categoriesText = this.getCategoriesText(filters!.categories).join();
        }
        if (filters!.contracts.length > 0) {
            contractsText = filters!.contracts.join();
        }
        if (filters!.products.length > 0) {
            productsText = this.getProductsText(filters!.products).join();
        }
        if (filters!.isRentaFuture !== null) {
            rentaFutureText = filters!.isRentaFuture
                ? Localizer.yes
                : Localizer.no;
        }

        return (
            <React.Fragment>
                <span className="row"><b>{Localizer.constructionSiteDetailsScheduleFiltersStatus}</b> &thinsp; {statusesText}</span>
                <span className="row"><b>{Localizer.constructionSiteDetailsScheduleFiltersRentType}</b> &thinsp; {rentTypesText}</span>
                <span className="row"><b>{Localizer.constructionSiteDetailsScheduleFiltersRenters}</b> &thinsp; {rentersText}</span>
                <span className="row"><b>{Localizer.constructionSiteDetailsScheduleFiltersCategories}</b> &thinsp; {categoriesText}</span>
                <span className="row"><b>{Localizer.constructionSiteDetailsScheduleFiltersContracts}</b> &thinsp; {contractsText}</span>
                <span className="row"><b>{Localizer.constructionSiteDetailsScheduleFiltersProducts}</b> &thinsp; {productsText}</span>
                <span className="row"><b>{Localizer.constructionSiteDetailsScheduleFiltersDepos}</b> &thinsp; {deposText}</span>
                <span className="row"><b>{Localizer.catalogRentaFuture}:</b> &thinsp; {rentaFutureText}</span>
            </React.Fragment>
        );
    }

    private renderServiceRequestModal(): React.ReactNode {
        return (
            this.state.serviceRequest &&
                <ServiceRequestModal
                    modalRef={this._serviceRequestModal}
                    serviceRequest={this.state.serviceRequest}
                    serviceCreated={this.props.serviceCreated}
                    constructionSite={this.constructionSite}
                    user={this.user}
                />
        );
    }

    private renderSchedulerInfoModal(): React.ReactNode {
        return (
            <Modal info
                   ref={this._schedulerInfoModal}
                   title={" "}
            >
                <OneColumn>
                    <div className={styles.schedulerInfoModalTitle}>
                        <Icon name={"info"}
                              size={IconSize.X4}
                              className={styles.schedulerInfoModalIcon}
                        />

                        <h2>
                            {
                                Localizer.constructionSiteDetailsCreateSchedule
                            }
                        </h2>

                        <Icon name={"info"}
                              size={IconSize.X4}
                              className={styles.schedulerInfoModalIcon}
                              customStyle={{visibility: "hidden"}}
                        />
                    </div>

                    <div className={styles.schedulerInfoModalContent}>
                        <div>
                            {
                                Localizer.constructionSiteDetailsSchedulerInfo
                            }
                        </div>

                        <Button className={this.css(styles.modalButtons)}
                                type={ButtonType.Orange}
                                label={"Ok"}
                        />
                    </div>
                </OneColumn>
            </Modal>
        );
    }

    private renderReturnProductForm(): React.ReactNode {
        if (this.userContext.isAdmin && !this.constructionSite?.ownerId && !this.props.contractId) {
            return null;
        }

        const chosenProductsCount = this.state.returnProducts.reduce((x, y) => x + y.count, 0);
        const headerText = chosenProductsCount === 0
            ? Localizer.constructionSiteDetailsReturnFormNothingSelected
            : Localizer.get(Localizer.constructionSiteDetailsReturnFormMultipleSelected, chosenProductsCount);

        return (
            <div id="returnProductsAccordion" className="p-1">
                <Accordion ref={this._returnItemsAccordionRef}
                           autoCollapse={false}
                           header={`${Localizer.constructionDetailsReturnChosen} (${headerText})`}
                           onToggle={async (sender, expanded) => await this.onOpenReturnAccordion(expanded)}
                           togglerPosition={TogglerPosition.Header}
                           togglerIcon={"angle-down"}
                           togglerSize={IconSize.X3}
                           className="new-accordion-style-v2 new-accordion-style-v2-expansionInfo"
                >
                    <ReturnProductForm
                        userContext={this.userContext}
                        noConstructionSite={this.noConstructionSite}
                        contractId={this.props.contractId}
                        constructionSiteId={this.props.constructionSiteId}
                        constructionSite={this.constructionSite}
                        equipments={this.state.equipments}
                        chosenProductsCount={chosenProductsCount}
                        returnProducts={this.state.returnProducts}
                        collapseAccordion={async () => await this._returnItemsAccordionRef.current?.collapseAsync()}
                        siteUsers={this.siteUsers}
                    />
                </Accordion>
            </div>
        );
    }

    private renderSendSummaryForm(): React.ReactNode {
        return (
            <Form ref={this._sendSummaryFormRef}
                  id="sendSummary"
                  onSubmit={async () => await this.sendSummaryAsync(this.state.filteredEquipment)}
                  className={styles.sendSummaryForm}
            >
                <div className={"row m-0"}>
                    <div className={"p-1 col-12 col-md-6 col-lg-5 mt-n2"}>
                        <EmailInput required
                                    id="receiver"
                                    label={Localizer.genericEmail}
                                    placeholder={Localizer.genericEmail}
                                    value={this.state.receiverEmail}
                                    onChange={async (_, value) => this.setState({receiverEmail: value})}
                        />
                    </div>

                    <div className={"p-1 col-12 col-md-3 col-lg-2 my-auto"}>
                        <Button submit
                                id="send_summary_btn"
                                label={Localizer.genericSendSummary}
                                disabled={!this.isSummaryFormValid}
                        />
                    </div>

                    <div className={"p-1 col-12 col-md-3 col-lg-2 my-auto"}>
                        <Button label={Localizer.constructionSiteDetailsDownloadCsv}
                                onClick={async () => await this.downloadCsvAsync(this.state.filteredEquipment)}
                        />
                    </div>

                    {
                        (!this.noConstructionSite) &&
                        <div className={this.css(styles.schedulerButtonContainer, "p-1 col-12 col-md-12 col-lg-3 my-auto")}>
                            <Button type={ButtonType.Orange}
                                    id="sendSummary_submit"
                                    label={Localizer.constructionSiteDetailsCreateSchedule}
                                    disabled={!this.isSummaryFormValid}
                            >
                                <Button.Action title={Localizer.schedulerDailyRuleName}
                                               onClick={async () => await this.scheduleSummaryAsync(Localizer.schedulerDailyRuleName, ConstructionSiteSchedules.Daily)}
                                />
                                <Button.Action title={Localizer.schedulerWeeklyRuleName}
                                               onClick={async () => await this.scheduleSummaryAsync(Localizer.schedulerWeeklyRuleName, ConstructionSiteSchedules.Weekly)}
                                />
                                <Button.Action title={Localizer.schedulerMonthlyRuleName}
                                               onClick={async () => await this.scheduleSummaryAsync(Localizer.schedulerMonthlyRuleName, ConstructionSiteSchedules.Monthly)}
                                />
                            </Button>

                            <Button type={ButtonType.Orange}
                                    icon={{name: "info"}}
                                    style={{maxWidth: '30px'}}
                                    onClick={async () => await this.openSchedulerInfoModalAsync()}
                            />
                        </div>
                    }
                </div>
            </Form>
        );
    }

    private renderEquipment(): React.ReactNode {
        return (
            <div className="p-1">
                {
                    (this.state.filteredEquipment.length > 0) && (this.state.filteredEquipment[0].items.length > 0)
                        ?
                        (
                            this.state.filteredEquipment.map((group: ConstructionSiteItemsGrouped, groupIndex: number) => (
                                (group.items.length > 0) &&
                                (
                                    <div className={styles.group}
                                         key={group.name + groupIndex}
                                    >
                                        <h4
                                            className={styles.heading}>
                                            {
                                                group.name
                                            }
                                        </h4>

                                        {
                                            group.items.map((equipment: EasyEquipment, equipmentIndex: number) =>
                                                (
                                                    <React.Fragment key={equipment.name + equipmentIndex}>

                                                        {
                                                            (equipmentIndex === 0) &&
                                                            (
                                                                <hr className="m-0"
                                                                    style={{borderColor: "grey"}}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            (!this.noConstructionSite) &&

                                                            <Equipment toggler
                                                                       showPrices={this.showPrices}
                                                                       canOrderServices={this.canOrderServices}
                                                                       canReturn={this.canReturnProducts}
                                                                       equipment={equipment}
                                                                       onAmountChange={async (model) => this.onAmountChangeHandlerAsync(model)}
                                                                       createServiceRequest={async (
                                                                           serviceType: ServiceType,
                                                                           location: number | null,
                                                                           deviceName: string,
                                                                           deviceInventoryNumber) => this.openServiceRequestModalAsync(serviceType, location, deviceName, deviceInventoryNumber)}
                                                            />
                                                        }

                                                        {
                                                            (this.noConstructionSite) &&

                                                            <Equipment toggler
                                                                       canReturn={this.canReturnProducts}
                                                                       onAmountChange={async (model) => this.onAmountChangeHandlerAsync(model)}
                                                                       showPrices={this.showPrices}
                                                                       canOrderServices={false}
                                                                       equipment={equipment}
                                                            />
                                                        }

                                                        <hr className="m-0"
                                                            style={{borderColor: "grey"}}
                                                        />

                                                    </React.Fragment>
                                                )
                                            )
                                        }

                                    </div>
                                )
                            ))
                        )
                        : (
                            <div className={styles.productsNotFound}>
                                <LoadingOrDataNotFound isLoading={this.state.isLoading}
                                                       noDataText={Localizer.constructionSiteDetailsProductsNotFound}/>
                            </div>
                        )
                }
            </div>
        );
    }

    private renderScheduleText(model: ConstructionSiteScheduleData | null): React.ReactNode {

        const rule: ConstructionSiteSchedules = model!.rule;

        let ruleText: string = Equipments.getScheduleRuleTest(rule);

        let filtersText: React.ReactNode = this.renderScheduleFilters(model!.filters);

        return (
            <React.Fragment>
                <div className={"container"}>
                    <span className={"row"}>
                        <b>{Localizer.genericEmail}:</b> &thinsp; {model!.email}.
                    </span>
                    <span className={"row"}>
                        <b>{Localizer.constructionSiteDetailsScheduleRuleText} &thinsp; </b>
                        {ruleText}.
                    </span>

                    <br/>

                    <span className={"row"}>
                        <b>{Localizer.constructionSiteDetailsScheduleFiltersText} </b>
                    </span>

                    {filtersText}
                </div>
                <br/>
            </React.Fragment>
        );
    }

    public async initializeAsync(): Promise<void> {
        const equipmentPageData: IEquipmentPageData = await this.getEquipmentAsync();
        const equipments: EasyEquipment[] = equipmentPageData.equipment;

        const equipmentFiltersData: IEquipmentFiltersData = this.createEquipmentFiltersAsync(equipments, equipmentPageData.categories);

        const constructionSite: ConstructionSiteModel | null = (equipmentPageData.constructionSite) ? equipmentPageData.constructionSite : null;
        const showPrices: boolean = equipmentPageData.showPrices;
        const canOrderServices: boolean = equipmentPageData.canOrderServices;
        const filteredEquipment: ConstructionSiteItemsGrouped[] = this.getFilteredEquipment(equipments, this.state.model, equipmentFiltersData);
        let mySchedules: ConstructionSiteScheduleModel[] = [];

        if (this.props.constructionSiteId) {
            mySchedules = await this.getMySchedules(this.props.constructionSiteId);
        }

        const temporaryContract: OrganizationContractModel | null = (this.userContext.isAdmin && constructionSite)
            ? await RentaEasyController.getContractAsync(constructionSite.ownerId, this)
            : null;

        this.setState({
            mySchedules: mySchedules ?? [],
            equipments,
            filteredEquipment,
            equipmentFiltersData,
            constructionSite,
            showPrices,
            canOrderServices,
            isLoading: false,
            temporaryContract
        });
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(styles.products, "d-flex flex-column gap-4")}>
                <div className={this.css(styles.equipmentCost, "p-1 pl-2 pt-3")}>
                    {this.showPrices && (
                        <>
                            <span>
                                {Localizer.equipmentInAMonth} {Utility.format(` {0:C} ${PriceHelper.environmentCurrencyCode}`, Equipments.getMonthlyIncome(this.state.filteredEquipment))}
                            </span>

                            <span>
                                {Localizer.equipmentInADay} {Utility.format(` {0:C} ${PriceHelper.environmentCurrencyCode}`, Equipments.getDailyIncome(this.state.filteredEquipment))}
                            </span>
                        </>
                    )}
                </div>

                <div>
                    {this.renderSendSummaryForm()}
                </div>

                {(this.mySchedules && this.mySchedules.length > 0) && (
                    <div className="p-1">
                        <Accordion key={"schedules_accordion"}
                                   className="new-accordion-style-v2 new-accordion-style-v2-expansionInfo"
                                   header={Localizer.constructionSiteDetailsActiveSchedules}
                                   togglerPosition={TogglerPosition.Header}
                                   togglerIcon={"angle-down"}
                                   togglerSize={IconSize.X3}
                        >
                            {this.mySchedules.map((item: ConstructionSiteScheduleModel, index: number) => (
                                <TwoColumns key={item.title + index}>
                                    <div>
                                        {this.renderScheduleText(item.scheduleDataModel)}
                                    </div>

                                    <Button type={ButtonType.Orange}
                                            icon={{name: "trash", size: IconSize.Normal}}
                                            label={Localizer.formDelete}
                                            onClick={async () => await this.deleteScheduleAsync(item, index)}
                                    />
                                </TwoColumns>
                            ))}
                        </Accordion>
                    </div>
                )}

                <div className="p-1">
                    <EquipmentToolbar model={this.state.model}
                                      equipmentFiltersData={this.equipmentFiltersData || undefined}
                                      onChange={async (model: EquipmentToolbarModel) => await this.filterEquipmentAsync(model)}
                    />
                </div>

                <div>
                    {(this.canReturnProducts && this.state.model.status !== EquipmentStatus.Returned) && (this.renderReturnProductForm())}
                </div>

                <div className="p-1">
                    <Form className={this.css(styles.searchForm, "pt-2 pb-4 row m-0")}>
                        <TextInput label={`${Localizer.searchWordText}:`}
                                   id={"equipment-list-search-input"}
                                   inline={this.desktop}
                                   className="col-12 col-sm-6 px-1 px-md-2 px-lg-3"
                                   value={this.state.model.searchQuery}
                                   onChange={async (_, value: string) => await this.setSearchQueryAsync(value)}
                        />
                        <Dropdown noWrap
                                  required
                                  className="col-12 col-sm-6 px-1 px-md-2 px-lg-3"
                                  align={DropdownAlign.Left}
                                  label={`${Localizer.constructionSiteDetailsOrdering}: `}
                                  inline={this.desktop}
                                  minWidth={150}
                                  filterMinLength={20}
                                  items={EnumProvider.getEquipmentSortByTypeItems()}
                                  orderBy={DropdownOrderBy.None}
                                  selectedItem={EnumProvider.getEquipmentSortByTypeItem(EquipmentSortByType.Category)}
                                  onChange={async (_, item) => await this.setSortByTypeAsync(item)}
                        />
                    </Form>
                </div>
                {this.renderEquipment()}

                {this.renderServiceRequestModal()}

                {this.renderSchedulerInfoModal()}

            </div>
        );
    }

    private async onOpenReturnAccordion(expanded: boolean): Promise<void> {
        if (!this.userContext.isAdmin) {
            return;
        }

        if (expanded && this.state.inviteUsers === null) {
            // If we open the page as an admin, the construction site is null, read the contractId from URL.
            const contractId: string | null = this.constructionSite?.ownerId ?? this.props.contractId ?? null;
            const siteUsers: ContractConstructionSiteUserModel[] = await RentaEasyController.getContractConstructionSitesUsersAsync(contractId, this);
            const users: SelectListItem[] = [];
            const invitedGroup = new SelectListGroup();
            invitedGroup.name = Localizer.contractDetailsInvited;
            siteUsers?.forEach(user => {
                if (!user.firstName || !user.lastName || user.constructionSiteId !== this.props.constructionSiteId) {
                    return;
                }
                const item = TransformProvider.toSelectListItem(user);
                if (user.hasPendingInvitation) {
                    item.group = invitedGroup;
                }
                users.push(item);
            });
            this.temporaryContract?.users?.forEach(user => {
                if (!user.firstName || !user.lastName) {
                    return;
                }

                const item = TransformProvider.toSelectListItem(user);
                if (user.hasPendingInvitation) {
                    item.group = invitedGroup;
                }
                users.push(item);
            });
            await this.setState({inviteUsers: users});
        }
    }
}
import React, {useEffect, useState} from "react";
import {ButtonType, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import {FleetMonitoringFilters, ViewControlProps} from "@/pages/FleetMonitoring/ViewControl/ViewControl";
import FiltersForm from "@/pages/FleetMonitoring/FiltersForm/FiltersForm";
import {getDevicesCountAsync} from "@/services/FleetService";
import Localizer from "@/localization/Localizer";
import styles from "./FiltersModal.module.scss";
import ButtonWithSpinner from "@/components/ButtonWithSpinner/ButtonWithSpinner";

interface FiltersModalProps extends ViewControlProps {
    isOpen: boolean;
    onClose(): void;
}

const FiltersModal: React.FC<FiltersModalProps> = ({
    isOpen,
    onClose,
    filters,
    onFilterAndSort,
    userRoleIsAdmin,
    userRoleContractId,
    userRoleConstructionSiteId,
}: FiltersModalProps) => {
    const _modalRef: React.RefObject<Modal> = React.createRef();

    const [internalFilters, setInternalFilters] = useState(filters);
    const [resultsCount, setResultsCount] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            _modalRef.current?.openAsync();
        } else {
            _modalRef.current?.closeAsync();
        }
    }, [_modalRef, isOpen]);
    
    useEffect(() => {
        setInternalFilters(filters);
    }, [filters]);
    
    const getResultsCount = async (filters: FleetMonitoringFilters) => {
        setIsLoading(true);
        try {
            // Admin can filter devices from every organization and construction site. Other users have a specific role, so their search is narrowed.
            let constructionSiteId = (userRoleIsAdmin || !userRoleConstructionSiteId) ? undefined : userRoleConstructionSiteId;
            let contractId = (userRoleIsAdmin || !userRoleContractId) ? undefined : userRoleContractId;
            let company = filters.company;
            if (filters.companyId?.length) {
                contractId = filters.companyId;
                company = undefined;
            }
            let constructionSite = filters.constructionSite;
            if (filters.constructionSiteId?.length) {
                constructionSiteId = filters.constructionSiteId;
                constructionSite = undefined;
            }

            const count = await getDevicesCountAsync(
                constructionSite,
                constructionSiteId,
                contractId,
                filters.deviceName,
                filters.productGroup,
                filters.productGroupId,
                company,
            );

            setResultsCount(count);
        } catch (error) {
            console.error('Error loading devices:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleFiltersChange = (filters: FleetMonitoringFilters) => {
        setInternalFilters(filters);
        getResultsCount(filters).catch();
    };
    
    const handleButtonClick = () => {
        onFilterAndSort(internalFilters);
        onClose();
    };

    return isOpen ? (
        <Modal id="filters-modal"
               ref={_modalRef}
               preventEsc preventClosingOnOutsideClick preventClosingOnInsideClick
               size={ModalSize.Large}
               title={Localizer.fleetMonitoringPageSearchAndFilterModalTitle}
               className={styles.modal}
               onClose={async () => onClose()}
        >
            <FiltersForm filters={internalFilters}
                         userRoleConstructionSiteId={userRoleConstructionSiteId}
                         userRoleContractId={userRoleContractId}
                         userRoleIsAdmin={userRoleIsAdmin}
                         onFilterAndSort={handleFiltersChange}
                         idPrefix="filters-modal_"
                         vertical
            />
            <ButtonWithSpinner className={styles.showResultsButton}
                               id={"show-results-button"}
                               spinning={isLoading}
                               onClick={async () => handleButtonClick()}
                               type={ButtonType.Orange}
                               label={`${Localizer.fleetMonitoringPageSearchAndFilterModalShowResults}${resultsCount !== undefined ? ` (${resultsCount})` : ""}`}
                               block
            />
        </Modal>  
    ) : null;
};

export default FiltersModal;
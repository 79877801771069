import React, {useEffect, useState, useContext} from "react";

import {Box, Text} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";
import styles from "../DeviceDetails.module.scss";
import {getDeviceUsageAsync} from "@/services/FleetService";
import {DeviceUsage} from "@/models/ProductLocation/Models/DeviceUsage";
import DeviceUsageChart from "@/pages/ProductLocation/TrackUnitDetails/DeviceUsageChart/DeviceUsageChart";

interface IDeviceDetailsUsageProps {

}

const DeviceDetailsUsage: React.FC<IDeviceDetailsUsageProps> = (props: IDeviceDetailsUsageProps) => {

    const context = useContext(DeviceDetailsContext);

    const [deviceUsage, setDeviceUsage] = useState<DeviceUsage[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const loadDeviceUsage = async (serialNumber: string, rentaId: string): Promise<void> => {
        try {
            setIsLoading(true);
            const response = await getDeviceUsageAsync(serialNumber, rentaId);
            if (response)
                setDeviceUsage(response);
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading device usage info:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (context.serialNumber && context.rentaId && context.rentaGroupId)
            loadDeviceUsage(context.serialNumber, context.rentaId);
    }, []);

    return (
        <div className={`${styles.deviceDetailsUsage} ${styles.moduleWrapper}`}>
            <Text tag="h3" transform="uppercase" className={styles.moduleTitle}>
                {Localizer.deviceDetailsUsageHistoryTitle}
            </Text>

            <Box className={styles.moduleContainer}>
                <Box className={styles.moduleContentContainer} display="flex" flexDirection="column" data-cy={"deviceUsage"}>
                    {deviceUsage?.length ? (
                        <DeviceUsageChart usageData={deviceUsage} />
                    ) : (
                        <Box p={16}>
                            <Text tag="span" weight="bold">{Localizer.deviceDetailsUsageHistoryNoUsageHistory}</Text>
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default DeviceDetailsUsage;
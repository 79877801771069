import React, {useEffect, useState} from "react";
import {Link, Modal, ModalSize, Spinner} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import {Button, ButtonSize, ButtonType} from "@renta-apps/renta-react-components";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import styles from "./UserExternalVerificationInfoModal.module.scss";

interface UserExternalVerificationInfoModalProps {
    isOpen: boolean;
    onClose(needsAdditionalExternalValidation: boolean): void;
}

const UserExternalVerificationInfoModal: React.FC<UserExternalVerificationInfoModalProps> = ({isOpen, onClose}) => {
    const _modalRef: React.RefObject<Modal> = React.createRef();

    useEffect(() => {
        if (isOpen) {
            _modalRef.current?.openAsync();
        } else {
            _modalRef.current?.closeAsync();
        }
    }, [_modalRef, isOpen]);
    
    return isOpen ? (
        <Modal id="user-external-verification-info-modal"
               ref={_modalRef}
               preventEsc preventClosingOnInsideClick
               size={ModalSize.Large}
               title={Localizer.applyCompanyAccountUserExternalVerificationModalTitle}
               className={styles.modal}
               onClose={async () => onClose(false)}
        >
            <div className={styles.userExternalVerificationInfoModal}>
                <div className={styles.userExternalVerificationInfoModalContent}>
                    <div>
                        {Localizer.applyCompanyAccountUserExternalVerificationModalText}
                    </div>
                </div>
                <div className={styles.userExternalVerificationInfoModalButtons}>
                    <Button onClick={() => onClose(false)}
                            size={ButtonSize.Default}
                            type={ButtonType.Secondary}
                            className={styles.button}
                            id="cancel-user-external-validation"
                    >
                        {Localizer.formCancel}
                    </Button>
                    <Button onClick={() => onClose(true)}
                            size={ButtonSize.Default}
                            type={ButtonType.Primary}
                            className={styles.button}
                            id="confirm-user-external-validation"
                    >
                        {Localizer.applyCompanyAccountUserExternalVerificationModalConfirmButton}
                    </Button>
                </div>
            </div>
        </Modal>
    ) : null;
}

export default UserExternalVerificationInfoModal;
import React, {useMemo, useState} from "react";
import EasyPlusInfoModal from "@/components/EasyPlus/EasyPlusInfoModal/EasyPlusInfoModal";
import {ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";
import {Button, ButtonProps} from "@renta-apps/renta-react-components";
import {requestContact} from "@/services/EasyPlusService";

interface EasyPlusFeatureButtonProps extends ButtonProps {
    children: React.ReactNode;
}

const EasyPlusFeatureButton: React.FC<EasyPlusFeatureButtonProps> = ({ onClick, children, ...restProps }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const easyPlusEnabled = useMemo(() => {
        return (ch.getContext() as UserContext)?.isEasyPlusUser ?? false;
    }, []);
    
    const onButtonClick = (target: EventTarget) => {
        if (!easyPlusEnabled) {
            setIsModalOpen(true);
            return;
        }
        onClick?.(target);
    };

    return (
        <>
            <Button {...restProps} onClick={onButtonClick} easyPlusFeature>
                {children}
            </Button>
            <EasyPlusInfoModal isOpen={isModalOpen}
                               onClose={() => setIsModalOpen(false)}
                               onContactMeClick={async () => await requestContact()}
            />
        </>
    )
};

export default EasyPlusFeatureButton;
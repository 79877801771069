import React, {createRef, FC, RefObject, useEffect, useState} from "react";
import Localizer from "@/localization/Localizer";
import {
    Button,
    ButtonType,
    Checkbox,
    ConfirmationDialog,
    DateInput,
    Dropdown,
    DropdownRequiredType,
    EmailInput,
    Form,
    Icon,
    IconStyle,
    IconSize,
    Inline,
    InlineType,
    OneColumn,
    SelectListItem,
    Spinner,
    TextAreaInput,
    TextInput,
    TwoColumns
} from "@renta-apps/athenaeum-react-components";
import {
    Text,
} from "@renta-apps/renta-react-components";
import {ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import styles from "@/pages/ConstructionSiteDetails/Equipments/Equipments.module.scss";
import InviteNewUserModal from "@/components/InviteNewUserModal/InviteNewUserModal";
import InviteNewUserRequest from "@/models/server/Requests/InviteNewUserRequest";
import ReturnModel from "@/models/server/ReturnModel";
import ConstructionSiteReturn from "@/models/server/ConstructionSiteReturn";
import {ConstructionSiteEquipment} from "@/models/server/ConstructionSiteEquipment";
import EasyEquipment from "@/models/server/EasyEquipment";
import InviteUserPostRequest, {InvitedUser} from "@/models/server/InviteUserPostRequest";
import {ContractUserModel} from "@/models/server/ContractUserModel";
import {ContractConstructionSiteUserModel} from "@/models/server/ContractConstructionSiteUserModel";
import {IResponse} from "@/pages/ConstructionSiteDetails/Equipments/types";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import {postInviteUser, postReturnProduct} from "@/services/ConstructionSiteService";
import PageDefinitions from "@/providers/PageDefinitions";

interface IReturnProductFormProps {
    userContext: any;
    noConstructionSite: boolean;
    contractId?: string;
    constructionSiteId?: string;
    constructionSite: ConstructionSiteModel;
    equipments: ConstructionSiteEquipment[];
    chosenProductsCount: number;
    returnProducts: ConstructionSiteReturn[];
    siteUsers: SelectListItem[];
    collapseAccordion: () => Promise<void>;
}

const ReturnProductForm: FC<IReturnProductFormProps> = (props: IReturnProductFormProps) => {
    const [isSpinning, setIsSpinning] = useState<boolean>(false);

    const [useContactPersonsDetails, setUseContactPersonsDetails] = useState<boolean>(false);

    const [returningOnBehalf, setReturningOnBehalf] = useState<string | null>(null);
    const [returningOnBehalfUser, setReturningOnBehalfUser] = useState<ContractUserModel | ContractConstructionSiteUserModel | null>(null);

    const [inviteUserRequest, setInviteUserRequest] = useState<InviteNewUserRequest | null>(null);

    const [availableForPickup, setAvailableForPickup] = useState<boolean>(false);

    const [address, setAddress] = useState<string>("");
    const [returnerEmail, setReturnerEmail] = useState<string>(props.userContext.isAdmin ? "" : props.userContext.user?.email ?? "");
    const [returnerPhone, setReturnerPhone] = useState<string>(props.userContext.isAdmin ? "" : props.userContext.user?.phoneNumber ?? "");
    const [city, setCity] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [pickupTime, setPickupTime] = useState<Date>(new Date());

    const _inviteNewUserModal: RefObject<InviteNewUserModal> = createRef();
    const _inviteUserButtonRef: RefObject<Button> = createRef();
    const _orderOnBehalfOfDropdownRef: RefObject<Dropdown<SelectListItem>> = createRef();
    const _confirmationRef: RefObject<ConfirmationDialog> = createRef();

    useEffect(() => {
        const address: string = (props.constructionSite) ? props.constructionSite.externalAddress! : "";
        setAddress(address);
    }, []);

    const setReturningOnBehalfAsync = async (user: ContractUserModel | ContractConstructionSiteUserModel | null): Promise<void> => {
        if (user?.phoneNumber) {
            setReturnerPhone(user.phoneNumber);
        }

        if (user?.email) {
            setReturnerEmail(user.email);
        }
        setReturningOnBehalf(user?.userId ?? null);
        setReturningOnBehalfUser(user ?? null);
    }

    const inviteNewUserAsync = async (request: InviteNewUserRequest): Promise<void> => {
        if (_inviteNewUserModal.current) {
            await _inviteNewUserModal.current.closeAsync();
        }

        setInviteUserRequest(request);
    }

    const openOrders = async (): Promise<void> => {
        if (props.constructionSite) {
            await PageRouteProvider.redirectAsync(PageDefinitions.orders.route({params: {constructionSiteId: props.constructionSite.id}}));
        }
    }

    const returnProductAsync = async (): Promise<void> => {
        const confirmation: ConfirmationDialog = _confirmationRef.current!;

        if (!await confirmation.confirmAsync(Localizer.confirmationOrderReturnForProducts)) {
            return;
        }

        if (props.userContext.isAdmin && !returningOnBehalf && (!inviteUserRequest?.firstName || !inviteUserRequest?.lastName)) {
            await ch.alertWarningAsync(Localizer.inviteUserInfoMissing, true, true);
            return;
        }

        const returnModel: ReturnModel = new ReturnModel();
        returnModel.products = [];
        returnModel.contactEmail = returnerEmail;
        returnModel.contactPhone = returnerPhone;
        returnModel.comment = comment;
        returnModel.returnedOnBehalf = returningOnBehalf;
        returnModel.invitedUserFirstName = inviteUserRequest?.firstName ?? "";
        returnModel.invitedUserLastName = inviteUserRequest?.lastName ?? "";
        returnModel.invitedUserEmail = inviteUserRequest?.email ?? "";
        returnModel.invitedUserTelephone = inviteUserRequest?.telephone ?? "";

        let returns: ConstructionSiteReturn[] = [];
        props.returnProducts.forEach(returnModel => {
            const match = (item: ConstructionSiteEquipment): boolean => (item.rentaId === returnModel.rentaId) && (item.name === returnModel.name) && (item.contractId === returnModel.contractNumber);

            const equipmentModel: ConstructionSiteEquipment | undefined = props.equipments.find(match);
            if (!equipmentModel) {
                return;
            }

            returnModel.orderLineId = Number.parseInt(equipmentModel.id);

            if (!equipmentModel.massProduct) {
                returns.push(returnModel);
            }
            else {
                let equipmentCount = equipmentModel.count ?? 0;

                // In case of mass product the equipmentCount has count for specific contractNumber. CombinedCount has totalCount
                if (returnModel.count > equipmentCount) {
                    let overFlow = returnModel.count - equipmentCount;
                    returnModel.count = equipmentCount;
                    returns.push(returnModel);

                    let index: number = 0;
                    while (overFlow >= 0) {
                        if (!equipmentModel.equipmentGroup) {
                            break;
                        }
                        let fromEquipmentGroup: EasyEquipment | undefined = equipmentModel.equipmentGroup[index];

                        if (!fromEquipmentGroup) {
                            break;
                        }
                        const equipmentCount = fromEquipmentGroup.count ?? 0;

                        let clone: ConstructionSiteReturn = {...returnModel};
                        clone.contractNumber = fromEquipmentGroup.contractId;
                        clone.count = equipmentCount > overFlow ? overFlow : equipmentCount;
                        clone.depotExternalId = fromEquipmentGroup.location ?? returnModel.depotExternalId;
                        clone.orderLineId = Number.parseInt(fromEquipmentGroup.id);
                        returns.push(clone);

                        if (equipmentCount > overFlow) {
                            break;
                        }
                        else {
                            index++;
                            overFlow = overFlow - equipmentCount;
                        }


                    }
                }
                else {
                    returns.push(returnModel);
                }
            }
        });

        returnModel.products = returns;
        returnModel.organizationId = props.contractId ?? null;
        returnModel.constructionSiteId = (!!props.constructionSite)
            ? props.constructionSite.id
            : null;

        returnModel.address = address;
        returnModel.city = city;
        returnModel.postalCode = postalCode;
        returnModel.availableForPickup = availableForPickup;
        returnModel.pickupTime = pickupTime;

        setIsSpinning(true);

        await postReturnProduct(returnModel);

        const request: InviteNewUserRequest | null = inviteUserRequest;

        if (request) {
            const users: InvitedUser[] = [];
            users.push({
                email: request.email,
                telephone: request.telephone,
                lastName: request.lastName,
                firstName: request.firstName,
            });

            const inviteUserRequest: InviteUserPostRequest = {
                contractId: request.contractId,
                usersList: users,
                accessToAllConstructionSites: request.accessToAllConstructionSites,
                companyMainUserRole: request.companyMainUserRole,
                constructionSiteAccesses: request.constructionSiteAccesses,
            };

            const result: IResponse = await postInviteUser(inviteUserRequest);

            setInviteUserRequest(null);

            if (result.alertMessage === "") {
                await ch.alertErrorAsync(result.errorMessage, true, true);
            }
            else {
                await ch.alertMessageAsync(result.alertMessage, true, true);
            }
        }

        setCity("");
        setPostalCode("");
        setComment("");
        await _orderOnBehalfOfDropdownRef.current?.unselectAllAsync();

        setIsSpinning(false);
        await props.collapseAccordion();
    }


    return (
        <>
            {(isSpinning) && (
                <Spinner global/>
            )}

            <Form id="returnProduct"
                  className="d-flex flex-column gap-3"
                  onSubmit={async () => await returnProductAsync()}
            >
                <Text tag={"h3"} size={"XL"} className={styles.sectionHeader}>
                    {Localizer.contractDetailsMakeOrderRetrievalAddressHeader}
                </Text>

                <OneColumn>
                    <TextInput required
                               id="address"
                               label={Localizer.genericAddress}
                               value={address}
                               onChange={async (_, value) => setAddress(value)}
                               className={styles.formInputGroup}
                    />
                </OneColumn>

                <TwoColumns>
                    <TextInput required
                               id="city"
                               label={Localizer.genericCity}
                               value={city}
                               onChange={async (_, value) => setCity(value)}
                    />

                    <TextInput required
                               id="postalCode"
                               label={Localizer.genericPostalCode}
                               value={postalCode}
                               onChange={async (_, value) => setPostalCode(value)}
                    />
                </TwoColumns>

                {(props.userContext.isAdmin) && (
                    <>
                        <Text tag={"h3"} size={"XL"} className={styles.sectionHeader}>
                            {Localizer.contractDetailsMakeOrderCustomerHeader}
                        </Text>

                        <TwoColumns>
                            {/* If a Renta sales-person is returning for a customer and they have Easy users, then one can be selected from a dropdown. */}
                            {/* The ID of the user populates the Return field ReturnedOnBehalf (typeof nullable Guid). */}
                            <Dropdown required={!inviteUserRequest?.firstName}
                                      ref={_orderOnBehalfOfDropdownRef}
                                      id="returnedOnBehalf"
                                      requiredType={DropdownRequiredType.Manual}
                                      label={Localizer.contractDetailsMakeOrderAsCustomer}
                                      items={props.siteUsers}
                                      disabled={isSpinning || !!inviteUserRequest?.firstName || !!inviteUserRequest?.lastName}
                                      onChange={async (_, item: any) => await setReturningOnBehalfAsync(item)}

                            />

                            {/* Shows the invited user info. */}
                            {(inviteUserRequest?.firstName) &&
                                <div>
                                    <div>
                                        <label className={`base-input-label d-flex ${styles.label}`}>
                                            {Localizer.contractDetailsMakeOrderAsCustomer}
                                        </label>
                                        <div className={styles.invitedUserInfo}>
                                            <span>
                                                {Localizer.inviteUserNotification}
                                            </span>
                                        </div>
                                        <div className={styles.invitedUserDetails}>
                                            <p>
                                                <span>{inviteUserRequest?.firstName} {inviteUserRequest?.lastName}</span>
                                                <span>{inviteUserRequest?.telephone}</span>
                                            </p>
                                            <Icon name="fa-xmark"
                                                  size={IconSize.X2}
                                                  style={IconStyle.Solid}
                                                  className={styles.deleteInvitationIcon}
                                                  onClick={async () => {
                                                      setInviteUserRequest(null);
                                                      await _orderOnBehalfOfDropdownRef.current?.unselectAllAsync();
                                                  }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* Renta sales-person can invite user if the client is not a Renta Easy user yet. */}
                            {/* If user is invited, the user info is used as order-on-behalf-of info and returnedOnBehalf drop-down is disabled. */}
                            {/* Disabled if user is selected from returnedOnBehalf drop-down. */}
                            <div>
                                <div id="PricingPeriodInfoLbl" className={`base-input-labe d-flex ${styles.label}`}>
                                    <label>
                                        {Localizer.inviteUserInfoText}
                                    </label>
                                </div>

                                <Button id="inviteNewUserButton"
                                        ref={_inviteUserButtonRef}
                                        label={Localizer.inviteUserContactPerson}
                                        type={ButtonType.Orange}
                                        disabled={isSpinning || !!returningOnBehalf}
                                        icon={{name: "circle-plus"}}
                                        onClick={async () => await _inviteNewUserModal.current?.openAsync()}
                                />
                            </div>
                        </TwoColumns>
                    </>
                )}

                {(props.userContext.isAdmin) && (
                    <>
                        <Text tag={"h3"} size={"XL"} className={styles.sectionHeader}>
                            {Localizer.contactInfoText}
                        </Text>

                        <Inline>
                            <Checkbox inline radio
                                      id={"return_use_customer_info"}
                                      value={useContactPersonsDetails}
                                      onChange={async (_value, checked) => {
                                          if (checked) {
                                              setReturnerEmail(returningOnBehalfUser?.email ?? "");
                                              setReturnerPhone(returningOnBehalfUser?.phoneNumber ?? "");
                                          }
                                          setUseContactPersonsDetails(checked);
                                      }}
                                      inlineType={InlineType.Right}
                                      label={Localizer.constructionSiteDetailsReturnUseCustomerContactInfo}
                            />
                            <Checkbox inline radio
                                      id={"return_fill_customer_info"}
                                      value={!useContactPersonsDetails}
                                      onChange={async (_value, checked) => {
                                          if (checked) {
                                              setReturnerEmail("");
                                              setReturnerPhone("");
                                          }
                                          setUseContactPersonsDetails(!checked);

                                      }}
                                      inlineType={InlineType.Right}
                                      label={Localizer.constructionSiteDetailsReturnFillCustomerContactInfo}
                            />
                        </Inline>
                    </>
                )}

                <TwoColumns>
                    <EmailInput required={inviteUserRequest === null}
                                id="contactEmail"
                                label={Localizer.genericContactEmail}
                                readonly={useContactPersonsDetails}
                                noValidate={useContactPersonsDetails}
                                value={returnerEmail}
                                onChange={async (_, value) => setReturnerEmail(value)}
                    />

                    <TextInput id="contactPhone"
                               label={Localizer.genericContactPhone}
                               readonly={useContactPersonsDetails}
                               value={returnerPhone}
                               onChange={async (_, value) => setReturnerPhone(value)}
                    />
                </TwoColumns>

                <Text tag={"h3"} size={"XL"} className={styles.sectionHeader}>
                    {Localizer.genericAdditionalInformation}
                </Text>

                <OneColumn>
                    <TextAreaInput id="comment"
                                   label={Localizer.genericComment}
                                   value={comment}
                                   onChange={async (_, value) => setComment(value)}
                    />
                </OneColumn>

                {Localizer.constructionSiteDetailsReturnTimeInfo}

                <OneColumn>
                    <Checkbox inline
                              inlineType={InlineType.Right}
                              label={Localizer.constructionSiteDetailsReturnPickupLabel}
                              value={availableForPickup}
                              onChange={async (_, value) => setAvailableForPickup(value)}
                    />
                </OneColumn>

                {(!availableForPickup) && (
                    <TwoColumns>
                        <div>
                            <DateInput showTime
                                       popup
                                       value={pickupTime}
                                       onChange={async (date) => setPickupTime(date)}
                            />
                        </div>
                    </TwoColumns>
                )}

                <div className="d-flex gap-2 align-items-center">
                    <Button submit
                            type={ButtonType.Orange}
                            label={Localizer.constructionDetailsReturnChosen}
                            disabled={props.chosenProductsCount === 0}
                    />

                    <InlineTooltip className={`ml-1 ${styles.returnItemsButtonInfo}`}
                                   text={Localizer.constructionSiteEquipmentsReturnEquipmentFormButtonTooltipText}
                    />

                    <a className="btn btn-default font-weight-bold pl-4"
                       onClick={async () => await openOrders()}
                    >
                        {Localizer.companyDetailsListOrders}
                    </a>
                </div>
            </Form>

            {(props.constructionSite?.ownerId || (props.noConstructionSite && props.contractId)) &&
                <InviteNewUserModal ref={_inviteNewUserModal}
                                    inviteNewUserAsync={async (request) => await inviteNewUserAsync(request)}
                                    contractId={props.constructionSite?.ownerId ?? props.contractId ?? null}
                                    constructionSiteId={props.constructionSite?.id ?? null}
                />
            }

            <ConfirmationDialog ref={_confirmationRef}/>
        </>
    )
}

export default ReturnProductForm;
import { ApiProvider } from "@renta-apps/athenaeum-react-common";
import { IPagedList, SortDirection } from "@renta-apps/athenaeum-toolkit";
import { DeviceModel } from "@/models/server/DeviceModel";
import DeviceDetailsRequest from "@/models/server/Requests/DeviceDetailsRequest";
import DeviceExtendedDetailsRequest from "@/models/server/Requests/DeviceExtendedDetailsRequest";
import ListDevicesPagedRequest from "@/models/server/Requests/ListDevicesPagedRequest";
import GetDevicesCountRequest from "@/models/server/Requests/GetDevicesCountRequest";
import {DeviceDetailedModel} from "@/models/server/DeviceDetailedModel";
import {DeviceDetails} from "@/models/server/DeviceDetails";
import {DeviceUsage} from "@/models/ProductLocation/Models/DeviceUsage";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import FluidLevelModel from "@/models/server/FluidLevelModel";
import GetDeviceLocationRequest from "@/models/server/Requests/GetDeviceLocationRequest";
import GetMachineDocumentsRequest from "@/models/server/Requests/GetMachineDocumentsRequest";

export const getDeviceDetailsAsync = async (
    assetId: string,
    rentaId: string,
    rentalObjectId: string,
): Promise<DeviceDetails> => {
    const request: DeviceDetailsRequest = {
        assetId: assetId,
        rentaId: rentaId,
        rentaGroupId: rentalObjectId,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDeviceDetails", request);
};

export const getDevicesDetailsAsync = async (devices: DeviceDetailsRequest[]): Promise<DeviceDetails[]> => {
    return ApiProvider.postAsync("/api/Fleet/GetDevicesDetails", {devices});
};

export const getDeviceDebugDetailsAsync = async (
    assetId: string,
    rentaGroupId: string,
    rentaId: string
): Promise<string> => {
    const request: DeviceDetailsRequest = {
        assetId: assetId,
        rentaGroupId: rentaGroupId,
        rentaId: rentaId,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDeviceDebugDetails", request);
};

export const getDeviceExtendedDetailsAsync = async (
    constructionSiteId: string | undefined,
    contractId: string | undefined,
    serialNumber: string,
    rentaId: string,
    rentaGroup: string,
    rentaGroupId: string,
): Promise<DeviceDetailedModel> => {
    const request: DeviceExtendedDetailsRequest = {
        constructionSiteId: constructionSiteId ?? null,
        contractId: contractId ?? null,
        trackUnitSerialNumber: serialNumber,
        rentaId: rentaId,
        rentaGroup: rentaGroup,
        rentaGroupId: rentaGroupId,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDeviceExtendedDetails", request);
};

export const getDeviceFuelLevelAsync = async (
    serialNumber: string,
    rentaId: string,
    rentalObjectId: string,
): Promise<FluidLevelModel> => {
    const request: DeviceDetailsRequest = {
        trackUnitSerialNumber: serialNumber,
        rentaId: rentaId,
        rentaGroupId: rentalObjectId,
    };

    return ApiProvider.postAsync(`/api/Locations/GetDeviceFuelLevel`, request);
}

export const getDeviceUsageAsync = async (
    serialNumber: string,
    rentaId: string,
): Promise<DeviceUsage[] | null> => {
    const request: GetDeviceLocationRequest = {
        trackingSerialNumber: serialNumber,
        rentaId: rentaId,
    };

    return ApiProvider.postAsync("/api/Locations/GetDeviceUsage", request);
};

export const getDeviceDocumentsAsync = async (
    rentaId: string
): Promise<FileModel[]> => {
    const request: GetMachineDocumentsRequest = {
        rentaId: rentaId,
    }
    return ApiProvider.postAsync("/api/Product/GetMachineDocuments", request);
};

export const getDevicesPagedListAsync = async (
    pageNumber: number,
    pageSize: number,
    sortColumnName: string | null,
    sortDirection: SortDirection | null,
    constructionSite: string | undefined,
    constructionSiteId: string | undefined,
    contractId: string | undefined,
    deviceName: string | undefined,
    productGroup: string | undefined,
    productGroupId: string | undefined,
    company: string | undefined
): Promise<IPagedList<DeviceModel>> => {
    const request: ListDevicesPagedRequest = {
        sortColumnName: sortColumnName,
        sortDirection: sortDirection,
        pageSize: pageSize,
        pageNumber: pageNumber,
        constructionSite: constructionSite ?? null,
        constructionSiteId: constructionSiteId ?? null,
        contractId: contractId ?? null,
        deviceName: deviceName ?? null,
        productGroup: productGroup ?? null,
        productGroupId: productGroupId ?? null,
        company: company ?? null,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDevicesPagedList", request);
};

export const getDevicesCountAsync = async (
    constructionSite: string | undefined,
    constructionSiteId: string | undefined,
    contractId: string | undefined,
    deviceName: string | undefined,
    productGroup: string | undefined,
    productGroupId: string | undefined,
    company: string | undefined
): Promise<number> => {
    const request: GetDevicesCountRequest = {
        constructionSite: constructionSite ?? null,
        constructionSiteId: constructionSiteId ?? null,
        contractId: contractId ?? null,
        deviceName: deviceName ?? null,
        productGroup: productGroup ?? null,
        productGroupId: productGroupId ?? null,
        company: company ?? null,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDevicesCount", request);
};

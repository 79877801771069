import React, {MouseEvent} from "react";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import {BaseComponent, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import styles from "./BreadCrumb.module.scss";
import {Icon, IconSize, IconStyle, Link} from "@renta-apps/athenaeum-react-components";
import PageDefinitions from "@/providers/PageDefinitions";

interface IBreadCrumbProps {
    className?: string;
    items: BreadCrumbItem[];
    navigate?: boolean;
    editMode?: boolean;
    onItemClick?(sender: BreadCrumbItem, index: number): Promise<void>;
}

interface IBreadCrumbState {
}

export default class BreadCrumb extends BaseComponent<IBreadCrumbProps, IBreadCrumbState> {

    public state: IBreadCrumbState = {};

    private async onBreadCrumbClick(e: MouseEvent, item: BreadCrumbItem, index: number): Promise<void> {
        e.preventDefault();
        this.props.onItemClick ? await this.props.onItemClick(item, index) : await this.defaultOnBreadCrumbClick(item, index);
    }

    private async defaultOnBreadCrumbClick(item: BreadCrumbItem, index: number): Promise<void> {
        if (item.route) {
            await PageRouteProvider.redirectAsync(item.route);
        } else if (this.props.navigate && item.path) {
            window.location.href = this.getPath(item);
        }
    }

    private get baseKey(): string {
        return `BreadCrumbs_${this.id}`;
    }

    private getPath(item: BreadCrumbItem): string  {
        return this.props.editMode ? item.path + '?editMode=true' : item.path;
    }

    public render(): React.ReactNode {
        return (
            <div key={this.baseKey}
                 id={"breadCrumb-container"}
                 className={this.css(styles.breadcrumbs, "not-small-device")}
                 style={{display: "flex"}}
            >
                <div className={styles.bcItems}
                     id={"bcItems"}
                >
                    <Link route={PageDefinitions.frontPage.route()}
                          className={this.css(styles.bcItem)}
                          key={`${this.baseKey}_home`}
                    >
                        <span id={`${this.baseKey}_home`}>
                            <Icon name={"home"}
                                  className={styles.homeIcon}
                                  size={IconSize.Large}
                                  style={IconStyle.Solid}
                            />
                        </span>
                    </Link>

                    {
                        (this.props.items ?? []).map((item, index) =>
                            <React.Fragment key={`${this.baseKey}_item_${index}`}>
                                <a href={this.getPath(item)}
                                   className={styles.bcItem}
                                   key={`${this.baseKey}_item_${index}`}
                                   onClick={async (e) => await this.onBreadCrumbClick(e, item, index)}
                                >
                                    <span id={`${this.baseKey}_item_${index}`}>
                                        {item.name}
                                    </span>
                                </a>
                                {
                                    (index !== this.props.items.length - 1) && (
                                        <span className={this.css(styles.divider, "breadcrumb-divider")}
                                              key={`${this.baseKey}_item_${index}_slash`}
                                              id={`${this.baseKey}_item_${index}_slash`}>
                                            /
                                        </span>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        );
    }
}